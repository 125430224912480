<template>
  <div class="wallet">
    <van-nav-bar title="余额钱包" left-arrow @click-left="$router.go(-1)" :border="false" fixed />
    <div class="info_btn" v-if="type == 1">
      <div class="text">可用余额</div>
      <div class="num">{{ info.balance }}</div>
      <div class="btn">
        <van-button type="primary" color="#04CFA7" to="withdrawal">提现</van-button>
        <van-button type="primary" color="#00C782" to="recharge">充值</van-button>
      </div>
    </div>
    <div class="info_btn" v-if="type == 2">
      <div class="text">积分余额</div>
      <div class="num">{{ info.point }}</div>
    </div>
    <div class="split_line"></div>
    <div class="content">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :offset="0">
        <div class="record_list" v-for="val in list" :key="val.id">
          <div>
            <div class="dest">{{ val.remark }}</div>
            <div class="time">{{ val.create_time }}</div>
          </div>
          <span :class="val.sign == 2 ? 'add' : 'num'">{{ val.amount }}</span>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type: this.$route.query.type,
      info: {},
      list: [],
      page: 1,
      loading: false,
      finished: false,
    }
  },
  created() {
    this.getdata()
  },
  methods: {
    getdata() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      })
      this.$post({
        url: "/app/member/memberInfo",
        success: (res) => {
          this.$toast.clear()
          this.info = res.data
        },
        tip: () => { },
      })
    },
    onLoad() {
      var page = this.page++
      this.$get({
        url: "/app/balance_log/balanceList",
        data: {
          balance_type: this.type,
          page: page,
        },
        success: (res) => {
          if (!res.data || res.data.data.length == 0) {
            this.finished = true
          } else {
            var list = res.data.data
            if (page == 1) {
              this.list = list
            } else {
              for (var i in list) {
                this.list.push(list[i])
              }
            }
          }
          this.loading = false
        },
        tip: () => {
          this.finished = true
        },
      })
    },
  },
}
</script>

<style scoped lang='scss'>
.wallet {
  width: 100%;
  height: 100vh;
  background: linear-gradient($theme 15%, #fff 5%);
  /deep/.van-nav-bar {
    background: $theme;
    .van-nav-bar__content {
      height: 12vw;
    }
    .van-nav-bar__content {
      height: 12vw;
    }
    .van-icon {
      color: #fff;
    }
    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
      color: #fff;
    }
  }
  .info_btn {
    margin: 3vw auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    width: 92vw;
    border-radius: 2vw;
    .text {
      margin-top: 12vw;
      font-size: 3.6vw;
    }
    .num {
      margin-top: 1vw;
      font-size: 6vw;
      font-weight: 550;
    }
    .btn {
      margin-top: 6vw;
      width: 83vw;
      height: 13vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      /deep/.van-button {
        width: 45%;
        border-radius: 1vw;
      }
    }
  }
  .split_line {
    margin-top: 7vw;
    height: 3vw;
    width: 100vw;
    background-color: #f9f9f9;
  }
  // 内容
  .content {
    width: 100%;
    height: auto;
    padding-bottom: 2vh;
    .record_list {
      padding: 4vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #f5f5f5;
      >div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .dest {
          font-size: 4.2vw;
          color: #000;
        }
        .time {
          margin-top: 2vw;
          font-size: 3.6vw;
          color: #999999;
        }
      }
      .num {
        font-size: 4.5vw;
        color: #007c51;
        font-weight: 550;
      }
      .add {
        font-size: 4.5vw;
        color: #f13b10;
        font-weight: 550;
      }
    }
  }
}
</style>