<template>
  <div class="outer">
    <van-nav-bar title="物流查询" left-arrow @click-left="onClickLeft" :border="false" fixed />
    <div class="one">
      <iframe :src="url" frameborder="0" scrolling="no" style="width:100%;height:100%;"></iframe>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  name: "app",
  data() {
    return {
      url: this.$route.query.url,
    }
  },
  created() {
    let ua = navigator.userAgent.toLowerCase()
    //android终端
    let isAndroid = ua.indexOf("Android") > -1 || ua.indexOf("Adr") > -1
    //ios终端
    let isiOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    //微信浏览器终端
    function isWeixinBrowser() {
      return /micromessenger/.test(ua) ? true : false
    }
    if (navigator.userAgent.match(/(Android)/i) && isWeixinBrowser()) {
      this.$dialog
        .alert({
          title: "友情提示",
          message: "请前往手机内置浏览器进行查看",
        })
        .then(() => {
          // on close
        })
    }
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
  },
}
</script>

<style scoped lang="scss">
.outer {
  width: 100%;
  height: 100vh;
  /deep/.van-nav-bar {
    background: #ffffff;
    .van-icon {
      color: #000;
    }
    .van-nav-bar__title {
      color: #000;
    }
  }
  .one {
    width: 100%;
    height: 90vh;
  }
}
</style>
