<template>
  <div class='loginbox'>
    <van-nav-bar title="登录" :border='false' />
    <div class="titbox">
      <p class="tit">您好，</p>
      <p class="tits">欢迎来到兴华农业，立即
        <router-link to="register">注册</router-link>
      </p>
    </div>
    <div class="frombox">
      <van-field clearable v-model="tell" label="账号" placeholder="请输入账号" />
      <van-field clearable v-model="password" type="password" label="登录密码" placeholder="请输入您的登录密码" />
      <div class="pass">
        <router-link to="password">忘记密码</router-link>
        <van-button round type="primary" color='#00C782' @click="loginBtn">登录</van-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      tell: "",
      password: "",
      logo: "",
    }
  },
  computed: {},
  watch: {},
  methods: {
    loginBtn() {
      if (!this.tell) return this.$toast("请输入账号")
      if (!this.password) return this.$toast("请输入登录密码")
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      })
      this.$post({
        url: "/app/auth/login",
        noToken: true,
        data: {
          nickname: this.tell,
          password: this.password,
        },
        success: (res) => {
          this.$toast.clear()
          localStorage.setItem("token", res.data.token)
          this.$toast("登录成功")
          setTimeout(() => {
            this.$router.replace({
              name: "/",
            })
          }, 1500)
        },
        tip: () => {},
      })
    },
    getlogo() {
      this.$get({
        url: "/app/entry/getEntry",
        success: (res) => {
          this.$toast.clear()
          this.logo = res.data.entry_logo
        },
        tip: () => {},
      })
    },
  },
  created() {
    // this.getlogo()
  },
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.loginbox {
  width: 100%;
  min-height: 100vh;
  height: auto;
  background: #fff;
  .titbox {
    width: 100%;
    padding: 10vw 6vw;
    .tit {
      width: 100%;
      line-height: 2;
      color: #06121e;
      font-size: 40px;
      font-weight: 550;
    }
    .tits {
      line-height: 1.5;
      width: 100%;
      color: #8c9198;
      font-size: 3.4vw;
      a {
        color: #007c51;
      }
    }
  }
  .frombox {
    width: 100%;
    padding: 8vh 1vw 2vw;
    /deep/.van-cell {
      margin-bottom: 4vw;
      padding: 3.5vw 2vw 3.5vw 0;
      border-radius: 8vw;
      background: rgba(255, 255, 255, 0.4);
      .van-field__label {
        text-align: center;
      }
    }

    .pass {
      width: 100%;
      padding: 1vw 4vw 1vw 5vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > a {
        color: #8c9198;
        font-size: 3.5vw;
      }
      /deep/.van-button {
        width: 30%;
      }
    }
    .tit {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 4vh;
      > a {
        color: #fff;
        font-size: 4vw;
      }
    }
  }
}
</style>
