<template>
  <div class='index'>
    <van-nav-bar title="提交订单" :border="false" @click-left="$router.go(-1)" left-arrow />
    <!-- <div class="contactway">
      <img src="../../../assets/img/i@2x.png" class="address_img" />
      <div class="right" v-if="addres.name" @click="$router.push('myAddress')">
        <p class="name">{{addres.name}} {{addres.mobile}}</p>
        <p class="address">{{addres.area_desc}}-{{addres.detail}}</p>
      </div>
      <div class="right" v-else>
        暂无地址，<span class="add" @click="$router.push('myAddress')">请添加地址</span>
      </div>
      <img src="../../../assets/img/gengduo@2x(1).png" alt="" class="righticon">
    </div> -->
    <div class="center">
      <img :src="httpPath + info.image" class="shangpin" />
      <div class="right">
        <p class="name">{{ info.name }}</p>
        <div class="down">
          <p class="price"><span>￥</span>{{ info.price }}</p>
          <p class="num">X{{ num }}</p>
        </div>
      </div>
    </div>
    <div class="allprice">￥{{ totalprice }}</div>
    <!-- <div class="paystyle">
      <p class="title">余额支付</p>
      <p>剩余余额<span class="money">{{member.balance}}</span></p>
      <van-field v-model="password" maxlength="6" label="支付密码" placeholder="请输入支付密码" clearable type="password" style="padding:2vw 0" />
    </div> -->
    <van-submit-bar :label='`共${num}件 合计：`' button-color="#00C782" :price="total" button-text="提交订单" @submit="onSubmit" />
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      id: this.$route.query.id,
      num: this.$route.query.num,
      password: "",
      addres: {},
      info: {},
      totalprice: 0,
      total: 0,
      member: {},
    }
  },
  created() {
    this.getdata()
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
    onSubmit() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      })
      this.$post({
        url: "/app/orders3/createOrder",
        data: {
          goods_id: this.id,
          num: this.num,
        },
        success: (res) => {
          this.$toast.clear()
          this.$toast(res.msg)
          setTimeout(() => {
            this.$router.push({
              name: "shopapayorder",
              query: { order_id: res.data.order_id },
            })
          }, 1200)
        },
        tip: () => { },
      })
    },

    getdata() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      })
      this.$post({
        url: "/app/goods3/info",
        data: {
          id: this.id,
        },
        success: (res) => {
          this.$toast.clear()
          this.info = res.data
          this.totalprice = (
            Number(this.info.price) * Number(this.num)
          ).toFixed(2)
          this.total = Number(this.info.price) * Number(this.num) * 100
        },
        tip: () => { },
      })

      // this.$post({
      //   url: "/app/member_address/addressList",
      //   success: (res) => {
      //     this.$toast.clear()
      //     if (res.data.data.length > 0) {
      //       this.addres = res.data.data[0]
      //     } else {
      //       this.$dialog
      //         .alert({
      //           title: "提示",
      //           message: "请添加收货地址！",
      //         })
      //         .then(() => {
      //           this.$router.push("myAddress")
      //         })
      //     }
      //   },
      //   tip: () => {},
      // })
    },
  },
}
</script>

<style scoped lang='scss'>
.index {
  width: 100%;
  min-height: 100vh;
  background: #f9f9f9;
  /deep/.van-nav-bar {
    background: #fff;
    .van-icon {
      color: #000;
    }
    .van-nav-bar__right {
      color: #000;
    }
    .van-nav-bar__title {
      color: #000 !important;
    }
  }
  .contactway {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 3vw;
    background: #fff;
    .address_img {
      width: 10vw;
      height: 10vw;
    }
    .right {
      flex: 1;
      padding: 0 2vw;
      .name {
        font-size: 4vw;
        color: #000;
      }
      .address {
        font-size: 3.8vw;
        color: #000;
      }
      .add {
        font-size: 3.8vw;
        color: #00c782;
      }
    }
    .righticon {
      width: 3vw;
      height: 6vw;
    }
  }
  .center {
    padding: 3vw;
    display: flex;
    flex-direction: row;
    border-top: 5px solid #f9f9f9;
    border-bottom: 1px solid #f9f9f9;
    background: #fff;
    .shangpin {
      width: 20vw;
      height: 20vw;
      border-radius: 2vw;
      margin-right: 3vw;
    }
    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      .name {
        font-size: 3.8vw;
        color: #000;
      }
      .down {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .price {
          color: #007c51;
          font-size: 3.6vw;
          font-weight: 550;
          span {
            font-size: 3vw;
          }
        }
        .num {
          font-size: 3.4vw;
          color: #999;
        }
      }
    }
  }
  .allprice {
    font-size: 5vw;
    font-weight: 550;
    color: #007c51;
    padding: 2vw 3vw;
    text-align: right;
    border-bottom: 1.5vh solid #f9f9f9;
    background: #fff;
  }
  .paystyle {
    padding: 3vw;
    background: #fff;
    font-size: 3.6vw;
    color: #666;
    .title {
      font-size: 4vw;
      color: #000;
      padding: 2vw 0;
    }
    .copy {
      font-size: 4vw;
      color: #00c782;
    }
    .ordernumber {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .money {
      margin-left: 10vw;
    }
  }
  .bottom {
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    height: auto;
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
  /deep/.van-submit-bar__price {
    color: #000000;
    font-size: 4vw;
    font-weight: 550;
  }
}
</style>
