<template>
  <!-- 添加或编辑地址 -->
  <div class="addOrEditAddress">
    <van-nav-bar :title="title" left-arrow @click-left="$router.go(-1)" :border="false" />
    <div class="content">
      <van-field v-model="name" label="姓名" placeholder="收货人姓名" />
      <van-field v-model="phone" label="联系电话" placeholder="请输入电话" maxlength="11" />
      <van-field v-model="add" label="收货地址" placeholder="请选择省 / 市 / 区" readonly @click="choseAddress" />
      <van-field v-model="address" label="详细地址" placeholder="街道门牌、楼层房间号等" />
      <div class="van-cell van-field">
        <div class="oneline">
          <div class="moren">设为默认收货地址</div>
          <van-switch v-model="is_default" active-color="#04CFA7" size="20" />
        </div>
      </div>
      <div class="btn">
        <van-button type="primary" color="#04CFA7" @click="onSave">保存</van-button>
        <!-- <van-button type="primary" color="#999" v-show="title == '编辑地址'" @click="onDelete(id)">删除</van-button> -->
      </div>
      <van-popup v-model="show" position="bottom">
        <van-area :value="addcode" :area-list="areaList" @confirm="confirmchose" @cancel="choseAddress" />
      </van-popup>
    </div>
  </div>
</template>

<script>
import areaList from "../../../assets/js/area"
export default {
  name: "addOrEditAddress",
  props: {},
  data() {
    return {
      id: "", //编辑地址的id
      title: "添加地址",
      pro_id: "", //   省主键id
      city_id: "", //	  市主键id
      area_id: "", //   区主键id
      address: "", //   详细地址
      name: "", //   收货人姓名
      phone: "", //   手机号
      is_default: false, //是否默认：1：默认 2：不默认
      pro_name: "", //  省名称
      city_name: "", // 市名称
      area_name: "", // 区名称

      add: "", //省 市 区组合
      show: false,
      addcode: "",
      areaList: areaList,
      searchResult: [],
    }
  },
  created() { },
  mounted() {
    if (this.$route.query.addOrEdit == "add") {
      this.title = "添加地址"
    } else {
      this.title = "编辑地址"
    }

    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.getAddressDetail()
    }
  },
  methods: {
    // 获取要编辑的地址详情
    getAddressDetail() {
      if (this.id) {
        this.$toast.loading({
          message: "加载中...",
          forbidClick: true,
          duration: 0,
        })
        this.$get({
          url: "/app/member_address/info",
          data: { id: this.id },
          success: (res) => {
            this.$toast.clear()
            // this.pro_id = res.data.pro_id //   省主键id
            // this.city_id = res.data.city_id //	  市主键id
            this.area_id = res.data.area_code //   区主键id
            this.address = res.data.detail //   详细地址
            this.name = res.data.name //   收货人姓名
            this.phone = res.data.mobile //   手机号
            this.is_default = res.data.is_default == 1 ? true : false //是否默认：1：默认 2：不默认
            // this.pro_name = res.data.pro_name //  省名称
            // this.city_name = res.data.city_name // 市名称
            // this.area_name = res.data.area_name // 区名称
            //
            this.add = res.data.area_desc
          },
          tip: () => { },
        })
      }
    },
    //选择城市后
    confirmchose(area) {
      console.log(area)
      this.add = area[0].name + " " + area[1].name + " " + area[2].name
      this.pro_name = area[0].name
      this.city_name = area[1].name
      this.area_name = area[2].name
      this.pro_id = area[0].code
      this.city_id = area[1].code
      this.area_id = area[2].code
      this.show = false
    },
    choseAddress() {
      this.show = !this.show
    },
    // 添加编辑地址
    onSave() {
      if (!this.add) return this.$toast("选择地区")
      let url = ""
      let obj = {}
      if (this.id) {
        url = "/app/member_address/edit"
        obj = {
          id: this.id,
          // pro_id: this.pro_id, //   省主键id
          // city_id: this.city_id, //	  市主键id
          area_code: this.area_id, //   区主键id
          detail: this.address, //   详细地址
          name: this.name, //   收货人姓名
          mobile: this.phone, //   手机号
          is_default: this.is_default == true ? 1 : 0, //是否默认：1：默认 2：不默认
          // pro_name: this.pro_name, //  省名称
          // city_name: this.city_name, // 市名称
          // area_name: this.area_name, // 区名称
        }
      } else {
        // 添加
        url = "/app/member_address/add"
        obj = {
          // pro_id: this.pro_id, //   省主键id
          // city_id: this.city_id, //	  市主键id
          area_code: this.area_id, //   区主键id
          detail: this.address, //   详细地址
          name: this.name, //   收货人姓名
          mobile: this.phone, //   手机号
          is_default: this.is_default == true ? 1 : 0, //是否默认：1：默认 2：不默认
          // pro_name: this.pro_name, //  省名称
          // city_name: this.city_name, // 市名称
          // area_name: this.area_name, // 区名称
        }
      }

      this.$post({
        url: url,
        data: obj,
        success: () => {
          this.$toast.clear()
          this.$toast({
            message: "成功",
            duration: 1500,
          })
          setTimeout(() => {
            this.$router.go(-1)
          }, 1500)
        },
        tip: () => { },
      })
    },
    // 删除地址
    onDelete(e) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确认要删除吗?",
        })
        .then(() => {
          this.$post({
            url: "/app/region/delete",
            data: { id: e },
            success: () => {
              this.$toast.clear()
              this.$toast({
                message: "删除成功",
                duration: 1500,
              })
              setTimeout(() => {
                this.$router.replace("/myAddress")
              }, 1500)
            },
            tip: () => { },
          })
        })
        .catch(() => {
          // on cancel
        })
    },
  },
}
</script>

<style scoped lang='scss'>
.addOrEditAddress {
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
  /deep/.van-nav-bar {
    background: none;
    .van-nav-bar__content {
      height: 12vw;
    }
    .van-icon {
      color: #000;
    }
    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
      color: #000;
    }
  }
  // 内容
  .content {
    height: calc(100vh - 12vw);
    overflow-y: auto;
    /deep/.van-cell {
      width: 100vw;
      height: 14vw;
      padding: 0 4vw;
      border-bottom: 1px solid #f5f5f5;
      // width: 92.53vw;
      // display: block;
      // box-shadow: 0vw 0vw 4.27vw 0vw rgba(96, 98, 102, 0.08);
      // border-radius: 2.13vw;
      // padding: 0 16px;
      // margin: 3.73vw;
      .van-field__label,
      .van-cell__value {
        display: flex;
        align-items: center;
        font-size: 4vw;
        font-weight: bold;
      }
    }
    /deep/.van-cell::after {
      border: 0;
    }
    .btn {
      margin: 60vw auto 0;
      width: 79vw;
      /deep/.van-button {
        margin: 0 auto;
        width: 79vw;
        border-radius: 2vw;
      }
      /deep/.van-button--normal {
        padding: 0;
        font-size: 4vw;
      }
    }
    .oneline {
      display: flex;
      line-height: 12.8vw;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      width: 92vw;
      &:last-child {
        border: none;
      }
      .name {
        width: 30%;
        height: 9vw;
        font-size: 4vw;
        font-weight: bold;
      }
      /deep/.text {
        font-size: 3.5vw;
      }
      .moren {
        font-size: 4vw;
        font-weight: bold;
        color: #646566;
      }
      input {
        flex: 1;
        font-size: 3.5vw;
        font-weight: 400;
        background: #fff;
        width: 90%;
      }
      input::-webkit-input-placeholder {
        font-size: 3.5vw;
        font-weight: 400;
        color: rgba(153, 153, 153, 1);
      }
      img {
        width: 5.87vw;
        height: 5.87vw;
        float: right;
        margin-top: 3.5vw;
      }
      textarea {
        flex: 1;
        font-size: 3.5vw;
        font-weight: 400;
        background: #fff;
        border: none;
        line-height: 4.5vw;
      }
      textarea::-webkit-input-placeholder {
        font-size: 3.5vw;
        font-weight: 400;
        color: rgba(153, 153, 153, 1);
      }
    }
    .block {
      display: block;
    }
  }
}
</style>