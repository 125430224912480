<template>
  <div class='index'>
    <van-nav-bar title="福利商城" :border='false' fixed right-text="订单" @click-right="$router.push('shopamyOrder')" />
    <template v-if="show">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :offset="0">
        <div class="listbox">
          <div class="list" v-for="val in list" :key="val.id"
            @click="$router.push({ name: 'shopadetails', query: { id: val.id } })">
            <img v-lazy="httpPath + val.image" alt>
            <div class="rightbox">
              <p class="name one">{{ val.name }}</p>
              <p class="tit one">{{ val.small_text }}</p>
              <p class="tit one">消耗:{{ val.point }}积分</p>
              <div class="money">
                <p class="one"><span>¥</span>{{ val.price }}</p>
                <van-icon name="add" size="24" color="#00c782" />
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </template>
    <van-empty v-if="!show" image="error" description="暂未开放" />
  </div>
</template>
<script>
export default {
  props: {},
  data() {
    return {
      show: 1,
      list: [],
      page: 1,
      loading: false,
      finished: false,
    }
  },
  created() {
    this.getdata()
  },
  methods: {
    getdata() {
      this.$post({
        url: "/app/auth/open",
        success: (res) => {
          this.show = Number(res.data.shop3_on)
        },
        tip: () => { },
      })
    },
    onLoad() {
      // this.$toast.loading({
      //   message: "加载中...",
      //   forbidClick: true,
      // })
      var page = this.page++
      this.$get({
        url: "/app/goods3/pageList",
        data: {
          page: page,
        },
        success: (res) => {
          this.$toast.clear()
          if (!res.data || res.data.data.length == 0) {
            this.finished = true
          } else {
            var list = res.data.data
            if (page == 1) {
              this.list = list
            } else {
              for (var i in list) {
                this.list.push(list[i])
              }
            }
          }
          this.loading = false
        },
        tip: () => {
          this.finished = true
        },
      })
    },
  },
}
</script>

<style scoped lang='scss'>
.index {
  width: 100%;
  min-height: 100vh;
  background: #f8f8f8;
  height: auto;
  padding: 7vh 0 0;
  /deep/.van-nav-bar {
    background: #00c782;
    .van-nav-bar__title {
      color: #fff;
      font-weight: 550;
    }
    .van-nav-bar__text {
      color: #fff;
    }
  }
  /deep/.van-list {
    padding-bottom: 10vh;
  }
  .listbox {
    width: 100%;
    padding: 2vw 3vw;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    .list {
      width: 48%;
      background: #fff;
      margin-bottom: 3vw;
      border-radius: 2vw;
      padding: 2vw;
      img {
        width: 100%;
        height: 41vw;
        border-radius: 2vw;
        object-fit: contain;
      }
      .rightbox {
        width: 100%;
        .name {
          width: 100%;
          color: #333333;
          font-size: 30px;
          font-weight: 550;
          line-height: 1.5;
        }
        .tit {
          width: 100%;
          color: #000;
          font-size: 26px;
        }
        .money {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          >p {
            width: 80%;
            color: #007c51;
            font-size: 34px;
            font-weight: 550;
            span {
              font-size: 24px;
            }
          }
        }
      }
    }
  }
}
</style>
