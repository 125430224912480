<template>
  <div class="mine">
    <van-nav-bar title="银行卡" left-arrow @click-left="onClickLeft" :border="false" />
    <!-- <van-field v-model="code" label="验证码:" center clearable placeholder="请输入短信验证码" v-if="isback">
      <template #button>
        <van-button size="small" color="#00C782" round type="default" v-if="is_time==1" @click="onSuccess">获取验证码</van-button>
        <van-button size="small" color="#00C782" round disabled v-else>{{time}}S后发送</van-button>
      </template>
    </van-field> -->
    <van-field v-model="bank_name" clearable label="开户行" placeholder="请输入开户行" />
    <van-field v-model="real_name" clearable label="开户人" placeholder="请输入开户人" />
    <van-field v-model="bank_no" clearable type="digit" label="银行卡卡号" placeholder="请输入银行卡卡号" maxlength="30" />
    <van-field v-model="bank_phone" clearable type="digit" label="手机号" placeholder="请输入手机号" maxlength="11" />
    <div class="btnbox">
      <van-button round color="#00C782" type="primary" size="large" @click="user_bank">保存</van-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: "",
      bank_phone: "",
      bank_no: "",
      bank_name: "",
      real_name: "",
      isback: false, //未绑定
      time: 60, //获取验证码 时间
      is_time: 1,
      code: "",
    }
  },
  created() {
    this.getAmountData()
  },
  watch: {
    bank_no(data) {
      this.bank_no = data.replace(/\s/g, "").replace(/(.{4})/g, "$1 ")
    },
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
    onSuccess() {
      this.$post({
        url: "/app/member/sendSms",
        data: {
          scene: "pay",
        },
        success: () => {
          this.$toast({
            message: "发送成功",
            duration: 1000,
          })
          this.is_time = 2
          this.time = 60
          let showtime = setInterval(() => {
            this.time--
            if (this.time == 1) {
              clearInterval(showtime)
              this.is_time = 1
            }
          }, 1000)
        },
        tip: () => { },
      })
    },
    getAmountData() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      })
      this.$post({
        url: "/app/member_pay/getPay",
        success: (res) => {
          this.$toast.clear()
          if (res.data.is_bind_bank == 1) {
            this.isback = true
            this.bank_phone = res.data.bank_phone
            this.bank_no = res.data.bank_no
            this.bank_name = res.data.bank_name
            this.real_name = res.data.bank_account_name
          }
        },
        tip: () => { },
      })
    },

    user_bank() {
      if (!this.bank_name) return this.$toast("请输入开户行")
      if (!this.real_name) return this.$toast("请输入开户人")
      if (!this.bank_no) return this.$toast("请输入银行卡卡号")
      if (!this.bank_phone) return this.$toast("请输入手机号")
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      })
      this.$post({
        url: "/app/member_pay/bindBank",
        data: {
          bank_phone: this.bank_phone,
          bank_name: this.bank_name,
          bank_account_name: this.real_name,
          bank_no: this.bank_no,
        },
        success: (res) => {
          this.$toast.clear()
          this.$toast.success({
            message: res.msg,
            duration: 1000,
          })
          setTimeout(() => {
            this.$router.go(-1)
          }, 1000)
        },
        tip: () => { },
      })
    },
  },
}
</script>
<style scoped lang="scss">
.mine {
  width: 100%;
  min-height: 100vh;
  background: #ffffff;
  height: auto;
  padding-bottom: 4vh;
  /deep/.van-nav-bar {
    .van-icon {
      color: #000;
    }
    .van-nav-bar__title {
      color: #000;
    }
  }
  .btnbox {
    width: 100%;
    margin-top: 10vh;
    padding: 0 3vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
