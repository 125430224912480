<template>
  <div class="mine">
    <van-nav-bar title="我的" />
    <div class="container">
      <div class="user_box pdlr04" @click="$router.push('user')">
        <img :src="httpPath + info.head_img" alt="" />
        <div class="user_info fy_jcsb flex1">
          <div class="fx_jcsb aic">
            <div class="fx_jcsb aic">
              <span class="nickname">{{ info.nickname }}</span>
            </div>
            <div class="vip_name">{{ info.level }}</div>
          </div>
          <div class="fx_jcsb aic" v-if="info.real_name">
            <span class="name">姓名：{{ info.real_name }}</span>
          </div>
          <div class="tel">{{ info.phone }}</div>
        </div>
      </div>
      <div class="wallet fx_jcsb aic">
        <div @click="xz_go('wallet?type=1')">
          <span>钱包余额</span>
          <span>{{ info.balance }}</span>
        </div>
        <div @click="xz_go('wallet?type=2')">
          <span>积分余额</span>
          <span>{{ info.point }}</span>
        </div>
      </div>
      <div class="order_box fx_jcsb pdlr04">
        <div @click="getorder(1)">
          <img src="../../assets/img/p@2x.png" alt="">
          <span>待付款</span>
        </div>
        <div @click="getorder(2)">
          <img src="../../assets/img/q@2x.png" alt="">
          <span>待发货</span>
        </div>
        <div @click="getorder(3)">
          <img src="../../assets/img/r@2x.png" alt="">
          <span>待收货</span>
        </div>
        <div @click="getorder(4)">
          <img src="../../assets/img/s@2x.png" alt="">
          <span>已完成</span>
        </div>
        <div @click="getorder(0)">
          <img src="../../assets/img/t@2x.png" alt="">
          <span>我的订单</span>
        </div>
      </div>
      <div class="split_line"></div>
      <div class="link_box">
        <van-cell title="我的用户" :icon="require('../../assets/img/u@2x.png')" is-link to="team" />
        <van-cell to="withdrawal" title="提现" :icon="require('../../assets/img/v@2x.png')" is-link />
        <van-cell to="paylist" title="收款信息" :icon="require('../../assets/img/w@2x.png')" is-link />
        <van-cell title="我的推荐" :icon="require('../../assets/img/x@2x.png')" is-link v-if="info.level_id == 0"
          @click="$toast('请先完成购买')" />
        <van-cell title="我的推荐" :icon="require('../../assets/img/x@2x.png')" is-link to="invita" v-else />
        <van-cell to="myAddress" title="收货地址" :icon="require('../../assets/img/y@2x.png')" is-link />
        <van-cell to="changepass" title="修改密码" :icon="require('../../assets/img/z@2x.png')" is-link />
        <van-cell to="changetell" title="修改手机号" :icon="require('../../assets/img/tel.png')" is-link />
        <van-cell title="退出登录" :icon="require('../../assets/img/aa@2x.png')" @click="showDialog" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      info: {},
    }
  },
  created() {
    this.getdata()
  },
  methods: {
    getdata() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      })
      this.$post({
        url: "/app/member/memberInfo",
        success: (res) => {
          this.$toast.clear()
          this.info = res.data
        },
        tip: () => { },
      })
    },
    getorder(key) {
      sessionStorage.setItem("trora", key)
      this.$router.push("myOrder")
    },
    //退出
    showDialog() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确定要退出吗?",
        })
        .then(() => {
          this.$post({
            url: "/app/member/logout",
            success: (res) => {
              this.$toast.success("退出成功")
              localStorage.removeItem("token")
              setTimeout(() => {
                this.$router.replace("login")
              }, 1000)
            },
          })
        })
        .catch(() => {
          // on cancel
        })
    },
  },
}
</script>

<style scoped lang="scss">
.pdlr04 {
  padding: 0 4vw;
}
.flex1 {
  flex: 1;
}
.fx_jcsb {
  display: flex;
  justify-content: space-between;
}
.fy_jcsb {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.aic {
  align-items: center;
}
.mine {
  /deep/.van-nav-bar {
    .van-nav-bar__content {
      height: 12vw;
      .van-nav-bar__title {
        font-size: 4.5vw;
        font-weight: bold;
        color: #000;
      }
      .van-ellipsis {
        overflow: visible;
      }
    }
  }
  .container {
    height: calc(92vh - 12vw);
    overflow: auto;
    .user_box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 5vw;
      >img {
        margin-right: 4vw;
        width: 17vw;
        height: 17vw;
      }
      .user_info {
        width: 100%;
        .nickname {
          margin-right: 3vw;
          font-size: 5vw;
          font-weight: bold;
        }
        .name {
          font-size: 3vw;
          color: #000;
          font-weight: 550;
        }
        .vip_name {
          width: 12vw;
          height: 6vw;
          background: #007c51;
          color: #fff;
          text-align: center;
          line-height: 6vw;
          border-radius: 2vw;
        }
        .tel {
          font-size: 3.4vw;
          color: #000;
        }
      }
    }
    .wallet {
      padding: 0 5vw;
      margin: 5vw auto 0;
      width: 92vw;
      height: 20vw;
      border-top-right-radius: 3vw;
      border-top-left-radius: 3vw;
      background: url("../../assets/img/o@2x.png") no-repeat;
      background-size: contain;
      display: flex;
      justify-content: space-between;
      align-items: center;
      >div {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        span {
          font-size: 4vw;
          color: #fff;
          font-weight: bold;
          &:nth-child(2) {
            font-size: 4.6vw;
          }
        }
      }
    }
    .order_box {
      margin-top: 5vw;
      >div {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        >img {
          margin-bottom: 3vw;
          height: 6vw;
          object-fit: contain;
        }
        >span {
          white-space: nowrap;
          font-size: 3.4vw;
        }
      }
    }
    .split_line {
      margin-top: 5vw;
      height: 3vw;
      width: 100vw;
      background-color: #f9f9f9;
    }
    .link_box {
      margin-bottom: 10vw;
      /deep/.van-cell {
        padding: 4vw;
        font-size: 4vw;
        color: #000;
        border-bottom: 1px solid #f5f5f5;
        .van-icon {
          font-size: 36px;
          margin-right: 2vw;
          line-height: 1;
          .van-icon__image {
            object-fit: contain;
            margin: 0;
          }
        }
      }
    }
  }
}
</style>

