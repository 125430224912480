<template>
  <div class='mine'>
    <van-nav-bar title="收款方式" left-arrow @click-left="$router.go(-1)" />
    <van-cell :icon="bank" title="银行卡" is-link to="bank" clearable :value="!info.is_bind_bank?'未绑定':'已绑定'" />
    <van-cell :icon="wx" title="微信" is-link to="wechat" clearable :value="!info.is_bind_wx?'未绑定':'已绑定'" />
    <van-cell :icon="ali" title="支付宝" is-link to="ali" :value="!info.is_bind_alipay?'未绑定':'已绑定'" />
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      info: {},
      bank: require("../../../assets/img/dh_icon_t@2x.png"),
      wx: require("../../../assets/img/k@2x.png"),
      ali: require("../../../assets/img/j@2x.png"),
    }
  },
  computed: {},
  watch: {},
  methods: {
    getdata() {
      this.$post({
        url: "/app/member_pay/getPay",
        success: (res) => {
          this.$toast.clear()
          this.info = res.data
        },
        tip: () => {},
      })
    },
  },
  created() {
    this.getdata()
  },
}
</script>
<style lang='scss' scoped>
.mine {
  width: 100%;
  min-height: 100vh;
  height: auto;
  background: #fff;
  /deep/.van-nav-bar {
    background: none;
    .van-icon {
      color: #000;
    }
  }
  /deep/.van-cell {
    background: none;
    .van-cell__left-icon {
      font-size: 5vw;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .van-cell__value {
      font-size: 4vw;
    }
  }
}
</style>
