<template>
  <div class="container">
    <van-nav-bar title="用户协议" fixed left-arrow @click-left="onClickLeft" :border='false' />
    <div class="content" v-html="list.content"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: "",
    }
  },
  created() {
    this.$get({
      url: "/app/auth/yhxy",
      success: (res) => {
        this.list = res.data
      },
      tip: () => {},
    })
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
  },
}
</script>

<style scoped lang="scss">
.container {
  width: 100vw;
  min-height: 100vh;
  height: auto;
  background: #fff;
  padding: 7.5vh 3vw 3vw;
  box-sizing: border-box;
  /deep/.van-nav-bar {
    background: #fff;
    .van-icon {
      color: #000;
    }
    .van-nav-bar__title {
      color: #000;
    }
  }

  .content {
    width: 95%;
    margin: 0 auto;
    box-sizing: border-box;
    background: rgba(0, 87, 255, 0.5);
    border-radius: 2vw;
    padding: 3vw;
    /deep/ img {
      max-width: 100%;
      margin: 8px 0;
    }
  }
}
</style>
