<template>
  <div class="mine">
    <van-nav-bar title="团队" left-arrow @click-left="$router.go(-1)" :border="false" fixed />
    <div class="info">
      <img v-if="info.head_img" :src="httpPath + info.head_img" alt="" />
      <div class="right">
        <p>{{info.nickname}}</p>
        <div class="bom">
          <!-- <span>推荐人:{{info.parent}}</span>
          <span>推荐码:{{info.parent_code}}</span> -->
        </div>
      </div>
    </div>
    <div class="num">
      <div class="top">我的用户</div>
      <div class="smbox">
        <div>
          <img src="../../../assets/img/one.png" alt="" />
          <p>我的推荐</p>
          <span>{{total.today_recommend}}</span>
        </div>
        <div>
          <img src="../../../assets/img/two.png" alt="" />
          <p>用户人数</p>
          <span>{{total.team_num}}</span>
        </div>
        <div>
          <img src="../../../assets/img/three.png" alt="" />
          <p>用户业绩</p>
          <span>{{total.team_amount}}</span>
        </div>
      </div>
      <div class="smbox" style="margin-top:1vw;">
        <div>
          <p>我的推荐业绩</p>
          <span>{{total.recommend_amount}}</span>
        </div>
      </div>
    </div>
    <div class="num">
      <div class="top">奖励记录</div>
      <div class="tab">
        <van-tabs v-model="active" color="#007C51" title-active-color="#007C51" @click="onClick">
          <van-tab v-for="(item, index) in tab" :key="index" :title="item.name">
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :offset="0">
              <table>
                <tr v-if="list && list.length > 0">
                  <th style="width: 40%">用户</th>
                  <th style="width: 30%">等级</th>
                  <th style="width: 30%">团队业绩</th>
                </tr>
                <tr v-for="(item, index) in list" :key="index">
                  <td class="left">
                    <img v-lazy="httpPath+item.head_img" alt="" />
                    <div class="one">
                      <p>{{item.nickname}}</p>
                      <p>{{item.phone}}</p>
                    </div>
                  </td>
                  <td class="one">{{item.level_name}}</td>
                  <td class="one">{{item.team_amount}}</td>
                </tr>
              </table>
            </van-list>
          </van-tab>
        </van-tabs>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      httpPath: this.httpPath,
      active: 0,
      tab: [
        {
          name: "零售",
        },
        {
          name: "批发",
        },
      ],
      list: [],
      page: 1,
      finished: false,
      loading: false,
      info: {},
      total: {},
    }
  },
  created() {
    this.get_info()
    this.get_total()
  },
  methods: {
    //获取个人信息
    get_info() {
      this.$post({
        url: "/app/member/memberInfo",
        success: (res) => {
          this.info = res.data
        },
      })
    },
    //团队统计
    get_total() {
      this.$post({
        url: "/app/member/teamCount",
        success: (res) => {
          this.total = res.data
        },
      })
    },
    onClick() {
      this.page = 1
      this.list = []
      this.finished = false
    },
    onLoad() {
      var page = this.page++
      this.$post({
        url: "/app/member/teamInfo",
        data: { level: this.active + 1, page },
        success: (res) => {
          if (!res.data) {
            this.finished = true
            return
          }
          if (res.data.data.length == 0) {
            this.finished = true
          } else {
            var ret = res.data.data
            if (page == 1) {
              this.list = ret
            } else {
              for (var x in ret) {
                this.list.push(ret[x])
              }
            }
          }
          this.loading = false
        },
        tip: () => {
          this.finished = true
        },
      })
    },
  },
}
</script>
<style scoped lang="scss">
.mine {
  width: 100%;
  min-height: 100vh;
  height: auto;
  background: #00c782;
  padding: 15vw 4vw 0;
  //顶部样式
  /deep/.van-nav-bar {
    background: transparent;
    .van-icon {
      color: #fff;
    }
    .van-nav-bar__title {
      color: #fff;
    }
  }
  .info {
    width: 100%;
    padding: 4vw;
    border-radius: 3vw;
    background-color: #22a578;
    display: flex;
    align-items: center;
    margin-bottom: 8vw;
    img {
      width: 19vw;
      height: 19vw;
      border-radius: 50%;
      margin-right: 2vw;
    }
    .right {
      flex: 1;
      color: #fff;
      p {
        font-size: 4vw;
        font-weight: 550;
        margin-bottom: 4vw;
      }
      .bom {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          font-size: 3vw;
        }
      }
    }
  }
  .num {
    background-color: #14ae95;
    background-color: 1vw;
    padding: 8vw 3vw 4vw;
    position: relative;
    margin-bottom: 8vw;
    .top {
      background: url("../../../assets/img/gg@2x.png");
      background-size: 100% 100%;
      width: 27vw;
      height: 9vw;
      position: absolute;
      top: -4vw;
      left: 32vw;
      color: #fff;
      font-size: 4vw;
      text-align: center;
      line-height: 9vw;
    }
    .smbox {
      width: 100%;
      height: 100%;
      border-radius: 1vw;
      padding: 4vw 0;
      background-color: #fff;
      display: flex;
      justify-content: space-around;
      align-items: center;
      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 11vw;
          height: 11vw;
          margin-bottom: 3vw;
        }
        span {
          color: #007c51;
          font-weight: bold;
        }
      }
    }
    .tab {
      width: 100%;
      height: 100%;
      border-radius: 1vw;
      background-color: #fff;
      overflow: hidden;
      table {
        width: 100%;
        margin-top: 2vw;
        border-radius: 3vw;
        tr {
          width: 100%;
          th {
            background: #cfe3dc;
            height: 9vw;
            font-size: 3vw;
            color: #007c51;
          }
          td {
            text-align: center;
            height: 12vw;
            color: #666;
            font-size: 3vw;
            background: #fff;
          }
          .left {
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 10vw;
              height: 10vw;
              margin: 0 1vw 0 0;
            }
          }
        }
      }
    }
  }
}
</style>
