<template>
  <div class='index'>
    <div class="headerbox">
      <van-nav-bar title="首页" :border='false' />
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="">
        <van-swipe-item v-for="val in banner" :key="val.id">
          <img v-lazy="httpPath + val.image_path" alt>
        </van-swipe-item>
      </van-swipe>
    </div>
    <van-notice-bar background="#00c782" color="#ffffff" :text="`尊敬的${name}您好，兴华农业欢迎您！`" />
    <div class="listtit">
      <img src="../../assets/img/goodsTitle.png" alt="">
    </div>
    <div class="listbox">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :offset="0">
        <div class="list" v-for="val in list" :key="val.id"
          @click="$router.push({ name: 'details', query: { id: val.id } })">
          <img v-lazy="httpPath + val.image" alt>
          <div class="rightbox">
            <p class="name two">{{ val.name }}</p>
            <p class="tit one">{{ val.small_text }}</p>
            <p class="money"><span>¥</span>{{ val.price }} <span>赠:{{ val.point }}积分</span></p>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
export default {
  props: {},
  data() {
    return {
      name: "",
      banner: [],
      list: [],
      page: 1,
      loading: false,
      finished: false,
    }
  },
  created() {
    this.getdata()
  },
  methods: {
    getdata() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      })
      this.$post({
        url: "/app/member/memberInfo",
        success: (res) => {
          this.$toast.clear()
          this.name = res.data.nickname
        },
        tip: () => { },
      })
      this.$post({
        url: "/app/slider/sliderList",
        success: (res) => {
          this.banner = res.data
        },
        tip: () => { },
      })
    },
    onLoad() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      })
      var page = this.page++
      this.$get({
        url: "/app/goods/pageList",
        data: {
          page: page,
        },
        success: (res) => {
          this.$toast.clear()
          if (!res.data || res.data.data.length == 0) {
            this.finished = true
          } else {
            var list = res.data.data
            if (page == 1) {
              this.list = list
            } else {
              for (var i in list) {
                this.list.push(list[i])
              }
            }
          }
          this.loading = false
        },
        tip: () => {
          this.finished = true
        },
      })
    },
  },
}
</script>

<style scoped lang='scss'>
.index {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: 0 0 120px;
  .headerbox {
    width: 100%;
    background: url("../../assets/img/in.png") no-repeat;
    background-size: 100% 80%;
    padding: 2vw 3vw;
    /deep/.van-nav-bar {
      background: none;
      margin-bottom: 2vh;
      .van-nav-bar__title {
        color: #fff;
        font-weight: 550;
      }
    }
    .my-swipe {
      width: 100%;
      overflow: hidden;
      border-radius: 2vw;
      /deep/.van-swipe-item {
        width: 100%;
        height: 100%;
        >img {
          width: 100%;
          height: 40vw;
          object-fit: contain;
        }
      }
    }
  }
  .listtit {
    width: 100%;
    padding: 2vw 0;
    display: flex;
    justify-content: center;
    align-items: center;
    >img {
      width: 40%;
      height: 6vw;
      object-fit: contain;
    }
  }
  .listbox {
    width: 100%;
    padding: 1vw 3vw;
    .list {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      img {
        width: 40%;
        height: 200px;
        border-radius: 2vw;
        object-fit: contain;
      }
      .rightbox {
        flex: 1;
        min-height: 210px;
        padding: 0 0 2vw 2vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-bottom: 1px solid #ebebeb;
        .name {
          width: 100%;
          color: #333333;
          font-size: 30px;
          font-weight: 550;
          line-height: 1.5;
        }
        .tit {
          width: 100%;
          color: #999999;
          font-size: 24px;
        }
        .money {
          width: 100%;
          color: #007c51;
          font-size: 34px;
          font-weight: 550;
          span {
            font-size: 24px;
            &:nth-child(2) {
              color: #000;
              font-size: 26px;
            }
          }
        }
      }
    }
  }
}
</style>
