<template>
  <div class="container">
    <van-nav-bar title="修改手机号" fixed left-arrow @click-left="onClickLeft" :border="false" />
    <van-field v-model="tell" label="旧手机号:" readonly />
    <van-field label-width="110px" v-model="code" label="旧手机号验证码:" center clearable placeholder="请输入短信验证码">
      <template #button>
        <van-button size="small" color="#00C782" round type="default" v-if="is_time == 1"
          @click="onSuccess">获取验证码</van-button>
        <van-button size="small" color="#00C782" round disabled v-else>{{ time }}S后发送</van-button>
      </template>
    </van-field>
    <van-field v-model="newtell" label="新手机号:" placeholder="请输入新手机号" clearable maxlength="11" />
    <van-field label-width="110px" v-model="new_sms_code" label="新手机号验证码:" center clearable placeholder="请输入短信验证码">
      <template #button>
        <van-button size="small" color="#00C782" round type="default" v-if="is_timea == 1"
          @click="onSuccessa">获取验证码</van-button>
        <van-button size="small" color="#00C782" round disabled v-else>{{ timea }}S后发送</van-button>
      </template>
    </van-field>
    <div class="btnbox">
      <van-button style="margin-top: 20vh;" block type="primary" round size="normal" color="#00C782"
        @click="editpass">确定</van-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tell: "",
      code: "",
      newtell: "",
      new_sms_code: "",
      time: 60, //获取验证码 时间
      is_time: 1,
      timea: 60, //获取验证码 时间
      is_timea: 1,
    }
  },
  created() {
    this.getdata()
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
    getdata() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      })
      this.$get({
        url: "/app/member/memberInfo",
        success: (res) => {
          this.$toast.clear()
          this.tell = res.data.phone
        },
        tip: () => { },
      })
    },

    onSuccess() {
      this.$post({
        url: "/app/member/sendSms",
        data: {
          scene: "bind_phone",
        },
        success: () => {
          this.$toast({
            message: "发送成功",
            duration: 1000,
          })
          this.is_time = 2
          this.time = 60
          let showtime = setInterval(() => {
            this.time--
            if (this.time == 1) {
              clearInterval(showtime)
              this.is_time = 1
            }
          }, 1000)
        },
        tip: () => { },
      })
    },

    onSuccessa() {
      this.$post({
        url: "/app/auth/sendSms",
        data: {
          phone: this.newtell,
          scene: "bind_phone",
        },
        success: () => {
          this.$toast({
            message: "发送成功",
            duration: 1000,
          })
          this.is_time = 2
          this.time = 60
          let showtime = setInterval(() => {
            this.time--
            if (this.time == 1) {
              clearInterval(showtime)
              this.is_time = 1
            }
          }, 1000)
        },
        tip: () => { },
      })
    },

    editpass() {
      if (!this.code)
        return this.$toast({
          message: "请输入旧手机号短信验证码",
          duration: 1500,
        })
      if (!this.newtell)
        return this.$toast({ message: "请输入新手机号", duration: 1500 })
      if (!/^1[23456789]\d{9}$/.test(this.newtell))
        return this.$toast({ message: "请输入正确手机号格式", duration: 1500 })
      if (!this.new_sms_code)
        return this.$toast({
          message: "请输入新手机号短信验证码",
          duration: 1500,
        })
      this.$post({
        url: "/app/member/editPhone",
        data: {
          sms_code: this.code,
          new_phone: this.newtell,
          new_sms_code: this.new_sms_code,
        },
        success: () => {
          this.$toast.success({
            message: "修改成功",
            duration: 1500,
          })
          setTimeout(() => {
            this.$router.go(-1)
          }, 1500)
        },
        tip: () => { },
      })
    },
  },
}
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: 7vh 0 2vh;
  background: #ffff;
  /deep/.van-nav-bar {
    .van-icon {
      color: #000;
    }
  }
  /deep/.van-cell {
    background: none;
  }
  .btnbox {
    width: 100%;
    padding: 0 4vw;
    /deep/.van-button {
      .van-button__text {
        color: #ffff;
        font-size: 4vw;
        font-weight: 550;
      }
    }
  }
}</style>
