<template>
  <div class="mine">
    <van-nav-bar title="推广名片" left-arrow @click-left="onClickLeft" :border="false" fixed />
    <div class="Box">
      <div class="qrbox">
        <div class="qr">
          <img :src="data.img" alt="">
          <p>{{data.invite_code}}</p>
        </div>
      </div>
      <div class="botbox">
        <van-button class="btn" block v-clipboard:copy="data.invite_code" v-clipboard:success="onCopy" v-clipboard:error="onError">复制邀请码</van-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      path: this.httpPath,
      data: {},
    }
  },
  created() {
    this.getdata()
  },
  methods: {
    onCopy: function () {
      this.$toast({
        message: "复制成功",
        duration: 400,
      })
    },
    onError: function () {
      this.$toast({
        message: "复制失败",
        duration: 400,
      })
    },

    onClickLeft() {
      this.$router.go(-1)
    },
    getdata() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      })
      this.$post({
        url: "/app/member/share",
        success: (res) => {
          this.$toast.clear()
          this.data = res.data
        },
        tip: () => {},
      })
    },
  },
}
</script>
<style scoped lang="scss">
.mine {
  width: 100%;
  min-height: 100vh;
  height: auto;
  background: url("../../../assets/img/inviteBG.png");
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  /deep/.van-nav-bar {
    background: none;
    .van-icon {
      color: #fff;
    }
    .van-nav-bar__title {
      color: #fff;
    }
  }
  .Box {
    width: 100%;
    padding: 3vw 3vw 5vw;
    .qrbox {
      width: 100%;
      height: 100%;
      background-color: #00a282;
      border-radius: 5vw;
      padding: 3vw;
      .qr {
        width: 100%;
        height: 100%;
        padding: 0 0 2vw;
        background-color: #fff;
        border-radius: 5vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          width: 40vw;
          height: 40vw;
          border-radius: 3vw;
        }
        p {
          font-size: 4vw;
          color: #000;
          font-weight: 550;
        }
      }
    }
    .botbox {
      width: 100%;
      .btn {
        width: 56vw;
        margin: 6vw auto 0;
        padding: 2vw 0;
        background-color: #ffe600;
        border-radius: 26vw;
        border: none;
      }
    }
  }
}
</style>
