<template>
  <div class="box">
    <van-nav-bar title="关于我们" left-arrow @click-left="onClickLeft" />
    <div class="body">
      <div class="content" v-html="list.about_us"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: {
        title: "",
        content: "",
      },
      id: "",
    }
  },
  created() {
    this.getlist()
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
    getlist() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      })
      this.$get({
        url: "/app/member/aboutUs",
        success: (res) => {
          this.$toast.clear()
          this.list = res.data
        },
        tip: () => { },
      })
    },
  },
}
</script>

<style scoped lang="scss">
//大盒子
.box {
  width: 100vw;
  min-height: 100vh;
  height: auto;
  box-sizing: border-box;
  background: #fff;
}
/deep/.van-nav-bar .van-icon {
  color: #000;
}
//图片尺寸
/deep/img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}
.body {
  width: 100%;
  .content {
    width: 100%;
    padding: 5px;
    font-size: 3.6vw;
    color: #333;
  }
}
</style>
