<template>
  <div class="download">
    <van-nav-bar title="下载APP" left-arrow @click-left="$router.go(-1)" :border="false" fixed />
    <div v-if="!isWeixin">
      <div class="header">
        <div class="logobox">
          <img src="../../../assets/img/logo.jpg" alt="" />
        </div>
      </div>
      <div class="mainbox">
        <van-button icon="down" type="info" @click="download(data.download)">下载安装</van-button>
        <p class="tit">适用于安卓手机</p>
        <div class="qrcodes" v-if="data.download">
          <div id="qrcode" ref="qrCode"></div>
        </div>
        <p class="tit" v-if="data.download">扫描二维码下载APP</p>
      </div>
    </div>
    <div v-if="isWeixin">
      <img src="../../../assets/img/down.png" alt="" style="width: 100vw; height: 100vh" />
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2"
export default {
  name: "download",
  props: {},
  data() {
    return {
      data: {},
      isWeixin: false,
    }
  },
  mounted() {
    this.isWeixin = /micromessenger/.test(
      window.navigator.userAgent.toLowerCase()
    )
  },
  created() {
    this.getdata()
  },
  methods: {
    download(href) {
      window.open(href)

      // 创建隐藏的可下载链接
      //   var eleLink = document.createElement("a")
      //   eleLink.setAttribute("href", href)
      //   eleLink.setAttribute("download", name)
      //   eleLink.style.display = "none"
      //   // 触发点击
      //   document.body.appendChild(eleLink)
      //   eleLink.click()
      //   // 然后移除
      //   document.body.removeChild(eleLink)
    },
    getdata() {
      this.$get({
        url: "/app/auth/download",
        success: (res) => {
          this.$toast.clear()
          this.data = res.data
          this.$nextTick(() => {
            this.bindQRCode(this.data.download)
          })
        },
        tip: () => {},
      })
    },
    bindQRCode: function (url) {
      new QRCode(this.$refs.qrCode, {
        text: url,
        width: 150,
        height: 150,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, // 容错率
      })
    },
  },
}
</script>

<style  lang="scss" scoped>
.download {
  width: 100%;
  height: 100vh;
  background: #ffffff;
  /deep/.van-nav-bar {
    background: none !important;
    .van-icon {
      color: #fff;
    }
    .van-nav-bar__title {
      color: #000000;
    }
  }
  .header {
    width: 100%;
    padding: 18vh 3vw 0;
    background: url("../../../assets/img/aac.png") no-repeat;
    background-size: 100% 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    .logobox {
      width: 40vw;
      height: 40vw;
      box-shadow: 0px 3px 6px rgba(6, 101, 228, 0.08);
      background: #ffffff;
      border-radius: 5vw;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 3vw;
      > img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .mainbox {
    width: 100%;
    padding: 4vw 0 2vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .name {
      font-size: 4vw;
      color: #000000;
      font-weight: 550;
    }
    /deep/.van-button {
      width: 40%;
      margin: 3vh 0 2vh;
      border-radius: 3vw;
    }
    .tit {
      font-size: 3.4vw;
      color: #999999;
    }
    .qrcodes {
      display: inline-block;
      margin: 4vw 0 2vw;
      background: #ffffff;
      border-radius: 2vw;
      padding: 2vw;
      border: 1px solid #fcfcfc;
      box-shadow: 0px 3px 6px rgba(6, 101, 228, 0.08);
    }
  }
}
</style>
