
<template>
  <div class="container">
    <van-nav-bar title="个人资料" left-arrow :border="false" @click-left="onClickLeft" />
    <div class="box">
      <label class="touxiang" for="files">
        <img :src=" httpPath + user.head_img" alt />
        <input type="file" id="files" style="display:none" @change="uploadImages($event)" ref="fronts" accept="image/*" />
      </label>
      <div class="list">
        <p>昵称</p>
        <div>
          <span>{{user.nickname}}</span>
          <!-- <img src="../../../assets/img/ii@2x.png" alt=""> -->
        </div>
      </div>
      <div class="list">
        <p>真实姓名</p>
        <div>
          <span>{{user.real_name}}</span>
        </div>
      </div>
      <div class="list">
        <p>手机号码</p>
        <div>
          <span>{{user.phone}}</span>
        </div>
      </div>
      <div class="list">
        <p>等级名称</p>
        <div>
          <span>{{user.level}}</span>
        </div>
      </div>
    </div>
    <van-dialog v-model="nameEditShow" title="修改昵称" show-cancel-button @confirm="editNameBtn">
      <van-field v-model="name" border placeholder="请输入修改的昵称" />
    </van-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      user: {},
      img: "",
      name: "",
      nameEditShow: false,
    }
  },
  created() {
    this.getdata()
  },
  methods: {
    getdata() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      })
      this.$post({
        url: "/app/member/memberInfo",
        success: (res) => {
          this.$toast.clear()
          this.user = res.data
        },
        tip: () => {},
      })
    },

    uploadImages(event) {
      var DOM = this.$refs.fronts
      var file = DOM.files[0]
      var formData = new FormData()
      formData.append("image", file)
      this.$post({
        url: "/app/member/editHead",
        data: formData,
        upload: true,
        success: (res) => {
          this.$toast("修改成功")
          setTimeout(() => {
            this.getdata()
          }, 1000)
        },
        tip: () => {},
      })
    },
    //修改昵称
    editNameBtn() {
      if (!this.name) return this.$toast("请输入修改的昵称")
      this.$post({
        url: "/app/member/editNick",
        data: {
          nickname: this.name,
        },
        success: (res) => {
          this.$toast("修改成功")
          this.name = ""
          setTimeout(() => {
            this.getdata()
          }, 1000)
        },
        tip: () => {},
      })
    },

    onClickLeft() {
      this.$router.go(-1)
    },
  },
}
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  min-height: 100vh;
  background: #f9f9f9;
  /deep/.van-nav-bar {
    background: #ffffff;
    .van-icon {
      color: #000;
    }
    .van-nav-bar__title {
      color: #000;
    }
  }
  /deep/ .van-field__label {
    width: 35px;
  }
  /deep/.van-popup {
    width: 60%;
    padding: 20px 10px;
    border-radius: 20px;
  }
  .box {
    width: 100%;
    .touxiang {
      width: 100%;
      padding: 4vh 3vw;
      text-align: center;
      img {
        width: 26vw;
        height: 26vw;
        border-radius: 50%;
      }
    }
    .list {
      width: 100%;
      padding: 3vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #ffffff;
      border-bottom: 1px solid #f9f9f9;
      img {
        width: 4vw;
        height: 4vw;
        object-fit: contain;
      }
      > p {
        font-weight: 550;
        color: #000;
        font-size: 3.6vw;
      }
      > div {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        > span {
          color: #999999;
          font-size: 3.4vw;
          margin-right: 10px;
        }
      }
    }
  }
  .editImg {
    width: 30px;
    height: 30px;
  }
}
</style>
