<template>
  <div class='indexbox'>
    <van-nav-bar title="支付订单" :border="false" @click-left="$router.go(-1)" left-arrow />
    <div class="bodybox">
      <div class="infobox">
        <div class="time">
          <p>支付剩余时间</p>
          <van-count-down :time="time">
            <template #default="timeData">
              <span class="block">{{ timeData.hours }}</span>
              <span class="colon">:</span>
              <span class="block">{{ timeData.minutes }}</span>
              <span class="colon">:</span>
              <span class="block">{{ timeData.seconds }}</span>
            </template>
          </van-count-down>
        </div>
        <p class="money"> <span>￥</span>{{ info.pay_money }}</p>
        <p class="name">{{ info.goods_name }}</p>
      </div>
      <van-radio-group v-model="radio">
        <van-cell-group v-if="is_app">
          <van-cell title="微信" :icon="wx" clickable @click="radio = 1">
            <template #right-icon>
              <van-radio :name="1" />
            </template>
          </van-cell>
          <van-cell title="支付宝" :icon="ali" clickable @click="radio = 2">
            <template #right-icon>
              <van-radio :name="2" />
            </template>
          </van-cell>
          <van-cell title="余额支付" clickable @click="radio = 4">
            <template #right-icon>
              <van-radio :name="4" />
            </template>
          </van-cell>
        </van-cell-group>
        <van-cell-group v-else>
          <van-cell title="支付宝" :icon="ali" clickable @click="radio = 3">
            <template #right-icon>
              <van-radio :name="3" />
            </template>
          </van-cell>
          <van-cell title="余额支付" clickable @click="radio = 4">
            <template #right-icon>
              <van-radio :name="4" />
            </template>
          </van-cell>
          <!-- <van-cell title="微信" :icon="wx" clickable @click="radio = 4">
            <template #right-icon>
              <van-radio :name="4" />
            </template>
          </van-cell> -->
        </van-cell-group>
      </van-radio-group>
      <van-button type="primary" block color="#00C782" :loading='loading' @click="payorder">立即支付</van-button>
    </div>

  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      order_id: this.$route.query.order_id,
      order: this.$route.query.order,
      info: {},
      radio: 1,
      ali: require("../../../assets/img/j@2x.png"),
      wx: require("../../../assets/img/k@2x.png"),
      loading: false,
      time: 0,
      is_app: false,
    }
  },
  computed: {},
  watch: {},
  methods: {
    getPlatform() {
      if (navigator.userAgent.match(/(Android)/i)) {
        window.android.getPlatform()
      } else if (navigator.userAgent.match(/(iPhone|iPod|ios)/i)) {
        window.webkit.messageHandlers.getPlatform.postMessage()
      }
    },
    getdata() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      })
      this.$post({
        url: "/app/orders2/info",
        data: {
          order_id: this.order_id,
        },
        success: (res) => {
          this.$toast.clear()
          this.info = res.data
          let timestart = Date.parse(new Date()) //当前时间
          let btime = new Date(this.info.pay_down.replace(/-/g, "/")).getTime() //结束时间
          this.time = Number(btime - timestart)
        },
        tip: () => { },
      })
    },
    payorder() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确定要支付订单吗?",
        })
        .then(() => {
          // on confirm
          this.$toast.loading({
            message: "支付中...",
            forbidClick: true,
          })
          this.loading = true
          this.$post({
            url: "/app/orders2/pay",
            data: {
              order_id: this.order_id,
              pay_type: this.radio,
            },
            success: (res) => {
              this.$toast.clear()
              this.loading = false
              if (this.radio == 1) {
                this.getpay(res.data)
              } else if (this.radio == 2) {
                this.getpay(res.data.data)
              } else if (this.radio == 3) {
                this.getH5pay(res.data.url)
              } else if (this.radio == 4) {
                this.$toast({
                  message: "支付成功",
                  duration: 1000,
                  onClose: () => {
                    this.$router.push({
                      name: "shopsend",
                      query: { order_id: this.order_id, order: this.order },
                    })
                  }
                })
              }
            },
            tip: () => {
              this.loading = false
            },
          })
        })
        .catch(() => {
          // on cancel
        })
    },
    //app 支付
    getpay(data) {
      let that = this
      if (navigator.userAgent.match(/(Android)/i)) {
        if (that.radio == 1) {
          let payData = JSON.stringify(data)
          console.log("Android appwx")
          window.android.call_wx_andriod(payData)
        } else if (that.radio == 2) {
          console.log("Android appali")
          window.android.call_ali_andriod(data)
        }
      } else if (navigator.userAgent.match(/(iPhone|iPod|ios)/i)) {
        if (that.radio == 1) {
          let payData = JSON.stringify(data)
          console.log("iPhone appwx")
          window.webkit.messageHandlers.call_wx_ios.postMessage(payData)
        } else if (that.radio == 2) {
          console.log("iPhone appali")
          window.webkit.messageHandlers.call_ali_ios.postMessage(data)
        }
      }
    },
    //H5 支付
    getH5pay(data) {
      if (navigator.userAgent.match(/(Android)/i)) {
        window.open(data)
      } else if (navigator.userAgent.match(/(iPhone|iPod|ios)/i)) {
        window.location.href = data
      }
      // const div = document.createElement("div")
      // div.innerHTML = data //res.data是返回的表单
      // document.body.appendChild(div)
      // document.forms[0].submit() //渲染支付宝支付页面
    },
    //app 回调函数
    payCallback(status) {
      if (status == "SUCCESS") {
        this.$toast({
          message: "支付成功",
          duration: 1000,
        })
        setTimeout(() => {
          this.$router.push({
            name: "shopsend",
            query: { order_id: this.order_id, order: this.order },
          })
        }, 1200)
      } else if (status == "FAIL") {
        this.$toast({
          message: "支付失败",
          duration: 1000,
        })
      } else if (status == "CANCEL") {
        this.$toast({
          message: "已取消支付！",
          duration: 1000,
        })
      }
    },
    platformCallback(text) {
      if (text == "qttx") {
        this.is_app = true
        this.radio = 1
      }
    },
  },
  created() {
    this.getdata()
    window.payCallback = this.payCallback
    try {
      window.platformCallback = this.platformCallback
      window.payCallback = this.payCallback
      this.getPlatform()
    } catch (error) {
      console.log(12, 'h5')
      this.radio = 3
    }
  },
  mounted() { },
  beforeCreate() { }, //生命周期 - 创建之前
  beforeMount() { }, //生命周期 - 挂载之前
  beforeUpdate() { }, //生命周期 - 更新之前
  updated() { }, //生命周期 - 更新之后
  beforeDestroy() { }, //生命周期 - 销毁之前
  destroyed() { }, //生命周期 - 销毁完成
  activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.indexbox {
  width: 100%;
  min-height: 100vh;
  background: #f4f4f4;
  /deep/.van-nav-bar {
    background: #fff;
  }
  .bodybox {
    width: 100%;
    padding: 2vw 4vw;
    .infobox {
      width: 100%;
      padding: 10vw 0;
      .time {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #999999;
        font-size: 24px;
        /deep/.van-count-down {
          margin-left: 2vw;
        }
      }
      .money {
        width: 100%;
        text-align: center;
        color: #000000;
        font-size: 76px;
        font-weight: 550;
        span {
          font-size: 32px;
        }
      }
      .name {
        width: 100%;
        text-align: center;
        color: #999999;
        font-size: 24px;
      }
    }
    /deep/.van-cell-group {
      background: none;
      .van-cell {
        margin-bottom: 2vh;
        .van-cell__left-icon {
          font-size: 40px;
          margin-right: 2vw;
        }
      }
    }
    /deep/.van-button {
      margin-top: 20vh;
      border-radius: 2vw;
    }
  }
}
</style>
