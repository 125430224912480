<template>
  <div class='index'>
    <van-nav-bar title="充值" left-arrow :border="false" @click-left="$router.go(-1)" />

    <van-field v-model="amount" label="充值金额" placeholder="请输入充值金额" clearable type="number" />
    <van-radio-group v-model="radio">
      <van-cell-group v-if="is_app">
        <van-cell title="微信" :icon="wx" clickable @click="radio = 1">
          <template #right-icon>
            <van-radio :name="1" />
          </template>
        </van-cell>
        <van-cell title="支付宝" :icon="ali" clickable @click="radio = 2">
          <template #right-icon>
            <van-radio :name="2" />
          </template>
        </van-cell>
      </van-cell-group>
      <van-cell-group v-else>
        <van-cell title="支付宝" :icon="ali" clickable @click="radio = 3">
          <template #right-icon>
            <van-radio :name="3" />
          </template>
        </van-cell>
      </van-cell-group>
    </van-radio-group>
    <p class="block"></p>
    <div style="text-align:center;padding:10vw 0">
      <van-button type="primary" size="large" :loading='loading' color="#00C782" round @click="submit"
        style="margin:0 3vw;width:90vw">
        确认充值
      </van-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      radio: 1,
      ali: require("../../../assets/img/j@2x.png"),
      wx: require("../../../assets/img/k@2x.png"),
      amount: "",
      loading: false,
      is_app: false,
    }
  },
  methods: {
    getPlatform() {
      if (navigator.userAgent.match(/(Android)/i)) {
        window.android.getPlatform()
      } else if (navigator.userAgent.match(/(iPhone|iPod|ios)/i)) {
        window.webkit.messageHandlers.getPlatform.postMessage()
      }
    },
    submit() {
      if (!this.amount) return this.$toast("请输入充值金额")
      this.loading = true
      this.$post({
        url: "/app/recharge/createOrder",
        data: {
          amount: this.amount,
        },
        success: (res) => {
          this.loading = false
          this.payorder(res.data.id)
        },
        tip: () => {
          setTimeout(() => {
            this.loading = false
          }, 1200)
        },
      })
    },
    payorder(id) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确定要支付订单吗?",
        })
        .then(() => {
          // on confirm
          this.$toast.loading({
            message: "支付中...",
            forbidClick: true,
            duration: 0,
          })
          this.loading = true
          this.$post({
            url: "/app/recharge/pay",
            data: {
              id,
              pay_type: this.radio,
            },
            success: (res) => {
              this.$toast.clear()
              this.loading = false
              if (this.radio == 1) {
                this.getpay(res.data)
              } else if (this.radio == 2) {
                this.getpay(res.data.data)
              } else if (this.radio == 3) {
                this.getH5pay(res.data.url)
              }
            },
            tip: () => {
              this.loading = false
            },
          })
        })
        .catch(() => {
          // on cancel
        })
    },
    //app 支付
    getpay(data) {
      let that = this
      if (navigator.userAgent.match(/(Android)/i)) {
        if (that.radio == 1) {
          let payData = JSON.stringify(data)
          console.log("Android appwx")
          window.android.call_wx_andriod(payData)
        } else if (that.radio == 2) {
          console.log("Android appali")
          window.android.call_ali_andriod(data)
        }
      } else if (navigator.userAgent.match(/(iPhone|iPod|ios)/i)) {
        if (that.radio == 1) {
          let payData = JSON.stringify(data)
          console.log("iPhone appwx")
          window.webkit.messageHandlers.call_wx_ios.postMessage(payData)
        } else if (that.radio == 2) {
          console.log("iPhone appali")
          window.webkit.messageHandlers.call_ali_ios.postMessage(data)
        }
      }
    },
    //H5 支付
    getH5pay(data) {
      if (navigator.userAgent.match(/(Android)/i)) {
        window.open(data)
      } else if (navigator.userAgent.match(/(iPhone|iPod|ios)/i)) {
        window.location.href = data
      }
      // const div = document.createElement("div")
      // div.innerHTML = data //res.data是返回的表单
      // document.body.appendChild(div)
      // document.forms[0].submit() //渲染支付宝支付页面
    },
    //app 回调函数
    payCallback(status) {
      if (status == "SUCCESS") {
        this.$toast({
          message: "支付成功",
          duration: 1000,
        })
        setTimeout(() => {
          this.$router.go(-1)
        }, 1200)
      } else if (status == "FAIL") {
        this.$toast({
          message: "支付失败",
          duration: 1000,
        })
      } else if (status == "CANCEL") {
        this.$toast({
          message: "已取消支付！",
          duration: 1000,
        })
      }
    },
    platformCallback(text) {
      if (text == "qttx") {
        this.is_app = true
        this.radio = 1
      }
    },
  },
  created() {
    window.payCallback = this.payCallback
    try {
      window.platformCallback = this.platformCallback
      window.payCallback = this.payCallback
      this.getPlatform()
    } catch (error) {
      console.log(12, 'h5')
      this.radio = 3
    }
  },
}
</script>

<style scoped lang='scss'>
.index {
  width: 100%;
  min-height: 100vh;
  background: #fff;
  /deep/.van-nav-bar {
    background: #fff;
    .van-icon,
    .van-nav-bar__text {
      color: #000;
    }
  }
  .tobox {
    width: 100%;
    padding: 2vw 3vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    >p {
      font-size: 3.6vw;
      color: #000000;
      font-weight: 550;
    }
    /deep/.van-dropdown-menu {
      flex: 1;
      .van-dropdown-menu__bar {
        box-shadow: none;
      }
    }
  }
  .titbox {
    width: 100%;
    padding: 2vw 3vw 2vw;
    color: #f13b10;
    font-size: 3.6vw;
    .tit {
      color: #000000;
      font-weight: 550;
    }
  }
  .cell {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1vw 3vw;
    color: #666666;
    font-size: 3.4vw;
  }
  /deep/.van-cell__title {
    display: flex;
    align-items: center;
  }
  /deep/.van-cell {
    .van-button__text {
      color: #000;
      font-size: 4vw;
    }
  }
  .block {
    width: 100%;
    height: 1vh;
    background: #f9f9f9;
  }
  .infobox {
    width: 100%;
    background: #f5f5f5;
    padding: 2vw 3vw;
    flex-wrap: 550;
    p {
      width: 50%;
      color: #333333;
      font-size: 4vw;
      span {
        color: #007c51;
      }
    }
  }
}
</style>
