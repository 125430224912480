<template>
  <!-- 收货地址 -->
  <div class="myAddress">
    <van-nav-bar title="收货地址" left-arrow @click-left="$router.go(-1)" :border="false" />
    <div class="content">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getAddressList" :offset="0">
        <div class="addressList">
          <div class="users" v-for="(ele, index) in AddressList" :key="index">
            <p>
              <span class="name">{{ ele.name }} {{ele.mobile}}</span>
              <span class="default" :class="ele.is_default == 1 ? 'yesDefault' : 'noDefault'" @click="onDefault(ele,index)">
                {{ ele.is_default == 1 ? "默认" : "设为默认" }}
              </span>
            </p>
            <p>
              <span class="ids">{{
              ele.area_desc  +'-'+ ele.detail
            }}</span>
            </p>
            <div class="operation">
              <span>
                <img src="../../../assets/img//ii@2x.png" alt="" style="width: 3vw; height: 3vw" @click="
                  $router.push({
                    name: 'addOrEditAddress',
                    query: { id: ele.id, addOrEdit: 'edit' },
                  })
                " />
              </span>
              <span style="display: flex;align-items: center;" @click="onDelete(ele.id)">
                <van-icon name="delete-o" size="15" color="#666" />
              </span>
              <!-- <span>
              <span class="select" :class="ele.id == order_id ? 'cur' : ''" v-show="is_order" @click="goConfirmOr(ele.id)">{{ ele.id == order_id ? "使用中" : "使用" }}</span>
            </span> -->
            </div>
          </div>
        </div>
      </van-list>
    </div>
    <div class="addAddress">
      <van-button type="primary" color="#04CFA7" @click="$router.push({name: 'addOrEditAddress',query: { addOrEdit: 'add' }})">添加地址</van-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "mineAddress",
  props: {},
  data() {
    return {
      AddressList: [],
      page: 1,
      loading: false,
      finished: false,
    }
  },
  created() {},
  mounted() {},
  methods: {
    getAddressList() {
      var page = this.page++
      this.$get({
        url: "/app/member_address/addressList",
        data: {
          page: page,
        },
        success: (res) => {
          this.$toast.clear()
          if (!res.data || res.data.data.length == 0) {
            this.finished = true
          } else {
            var list = res.data.data
            if (page == 1) {
              this.AddressList = list
            } else {
              for (var i in list) {
                this.AddressList.push(list[i])
              }
            }
          }
          this.loading = false
        },
        tip: () => {
          this.finished = true
        },
      })
    },
    // 设值为默认地址
    onDefault(data) {
      if (data.is_default !== 1) {
        this.$post({
          url: "/app/member_address/setDefault",
          data: {
            id: data.id,
          },
          success: (res) => {
            this.$toast({
              message: "成功",
              duration: 1500,
            })
            setTimeout(() => {
              this.page = 1
              this.AddressList = []
              this.finished = false
            }, 1200)
          },
          tip: () => {},
        })
      }
    },
    // 删除地址
    onDelete(e) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确认要删除吗?",
        })
        .then(() => {
          this.$post({
            url: "/app/member_address/del",
            data: { id: e },
            success: () => {
              this.$toast({
                message: "删除成功",
                duration: 1500,
              })
              setTimeout(() => {
                this.page = 1
                this.AddressList = []
                this.finished = false
              }, 1200)
            },
            tip: () => {},
          })
        })
        .catch(() => {
          // on cancel
        })
    },
  },
}
</script>

<style scoped lang='scss'>
.myAddress {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
  /deep/.van-nav-bar {
    background: none;
    .van-nav-bar__content {
      height: 12vw;
    }
    .van-icon {
      color: #000;
    }
    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
      color: #000;
    }
    .van-nav-bar__text {
      color: #666;
    }
  }
  // 内容
  .content {
    height: calc(93vh - 12vw);
    overflow-y: auto;
    .addressList {
      padding: 4vw 0;
      .users {
        padding: 4vw 0 0 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        border-bottom: 3vw solid #f9f9f9;
        p {
          padding: 0 4vw;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .operation {
          margin-top: 1vw;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          border-top: 1px solid #f9f9f9;
          height: 9vw;
          padding: 0 2vw;
          > span {
            margin-left: 4vw;
          }
        }
        .name {
          font-size: 4.2vw;
          font-weight: bold;
          color: #000;
          margin-right: 3vw;
        }
        .default {
          display: inline-block;
          padding: 0 1vw;
          font-size: 3.6vw;
          color: #fff;
          border-radius: 1vw;
          transform: scale(0.8);
        }
        .yesDefault {
          background-color: $dark;
        }
        .noDefault {
          background-color: #5f5f5f;
        }
        .ids {
          margin-top: 2vw;
          font-size: 3.4vw;
          font-weight: 550;
          color: #666666;
        }
        .select {
          padding: 0 1vw;
          border: 1px solid #666666;
          color: #666666;
          border-radius: 1vw;
          transform: scale(0.8);
        }
        .cur {
          border: 1px solid $theme;
          color: $theme;
        }
      }
    }
  }
  .addAddress {
    position: absolute;
    bottom: 0;
    left: 0;
    /deep/.van-button {
      margin: 0;
      width: 100vw;
      height: 7vh;
      border-radius: 0vw;
    }
    /deep/.van-button--normal {
      padding: 0;
    }
  }
}
</style>