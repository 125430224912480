import router from '../router/index';
// import vm from  'vue';

//小政提取的公共方法
export default {
  install(Vue, options) {

    //1.跳转路由带参数和不带参数,带参数请将要跳转的页面名称传入
    //如何拿去参数 
    // created() {
    //   console.log("获取的id" + this.$route.query.id);
    // },

    Vue.prototype.xz_cut = function (num, cut) {
      console.log("小政截取金额")
      return Number(num).toFixed(cut+1).slice(0, -1);
      
    }
    Vue.prototype.xz_go = function (name, id) {
      router.push({ path: name, query: { id: id } });
      console.log("小政跳转路由")
    }
    Vue.prototype.xz_id = function () {
      console.log("小政获取路由参数")
      return router.app._route.query.id;
    }
    //如何拿去参数 
    Vue.prototype.xz_goto = function (name, item) {
      router.push({ path: name, query: { item: JSON.stringify(item) } });
      console.log("小政跳转路由JOSN格式")
    }

    Vue.prototype.xz_item = function () {
      console.log("小政获取JOSN格式数据")
      return JSON.parse(router.app._route.query.item);
    }

    Vue.prototype.xz_goin = function (name) {
      router.replace({ path: name });
      console.log("小政关闭页面跳转路由")
    }
    //2.返回上一页
    Vue.prototype.xz_back = function () {
      router.go(-1);
      console.log("小政返回上一页")
    }

    // 3.本地存储封装
    Vue.prototype.xz_save = {
      set(key, value) {
        localStorage.setItem(key, value);
        console.log("小政保存到本地操作");
      },
      get(key) {
        console.log("小政获取本地数据");
        return localStorage.getItem(key);
      },
      del(key) {
        console.log("小政删除本地数据");
        localStorage.removeItem(key);
      }
    }
    Vue.prototype.xz_say = function (val) {
      //页面滚动到顶部
      console.log('小政说话了');
      Vue.prototype.$toast(val);

    }
    Vue.prototype.xz_top = function () {
      //页面滚动到顶部
      console.log('小政回到顶部');
      window.scrollTo(0, 0);
    }

  },

}