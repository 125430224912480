<template>
  <div class="box">
    <van-nav-bar title="资讯详情" left-arrow @click-left="onClickLeft" :border="false" fixed />
    <div class="body">
      <div class="title">{{ list.title }}</div>
      <div class="time">
        <span class="iconfont icon_shijian"> {{list.create_time}}</span>
      </div>
      <div class="content" v-html="list.content"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: {
        title: "",
        content: "",
      },
      id: "",
    }
  },
  created() {
    this.id = this.$route.query.id
    this.getlist()
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
    getlist() {
      this.$get({
        url: "/app/article/info",
        data: {
          id: this.id,
        },
        success: (res) => {
          this.list = res.data
        },
        tip: () => {},
      })
    },
  },
}
</script>

<style scoped lang="scss">
//大盒子
.box {
  width: 100vw;
  min-height: 100vh;
  height: auto;
  box-sizing: border-box;
  background: #fff;
}
/deep/.van-nav-bar .van-icon {
  color: #000;
}

.body {
  width: 100%;
  padding: 2vw 3vw 3vw;
  .title {
    width: 100%;
    font-size: 4.2vw;
    font-weight: bold;
    color: #000;
  }

  .time {
    width: 100%;
    color: #999;
    font-size: 3.3vw;
    text-align: left;
    .icon_shijian {
      font-size: 3.3vw;
    }
  }

  .content {
    width: 100%;
    font-size: 3.6vw;
    color: #333;
    /deep/ * {
      word-break: break-word;
      max-width: 100% !important;
      width: auto !important;
    }
    /deep/p {
      img {
        width: auto !important;
        height: auto !important;
        max-width: 100% !important;
        max-height: 100% !important;
        object-fit: contain;
      }
      video {
        width: auto !important;
        height: auto !important;
        max-width: 100% !important;
        max-height: 100% !important;
        object-fit: contain;
      }
    }
  }
}
</style>
