<template>
  <div class='index'>
    <van-nav-bar title="商品详情" left-arrow @click-left="$router.go(-1)" :border="false" />
    <div class="bannbox">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#fff">
        <van-swipe-item v-for="val in slider" :key="val.id">
          <img v-lazy="httpPath + val" alt>
        </van-swipe-item>
      </van-swipe>
    </div>

    <div class="minbox">
      <p class="name">{{ info.name }}</p>
      <div class="small_box">
        <p class="money"><span>￥</span>{{ info.price }}</p>
        <p class="num">消耗:{{ info.point }}积分</p>
      </div>
    </div>
    <div class="block"></div>
    <div class="tip">
      <img src="../../../assets/img/h@2x.png" alt="">
    </div>
    <div v-html="info.content" class="shangpin"></div>

    <van-goods-action>
      <van-goods-action-button type="danger" color="#00C782" text="立即购买" @click="buy_show = true" />
    </van-goods-action>

    <van-popup v-model="buy_show" title="选择数量" round :closeable="true" position="bottom" :safe-area-inset-bottom='true'>
      <div class="popup">
        <p class="tit">选择数量</p>
        <div class="listbox">
          <img :src="httpPath + info.image" alt />
          <div>
            <p>￥{{ info.price }}</p>
            <div class="stepper">
              <p>库存:{{ info.stock }}</p>
              <van-stepper disable-input v-model="pay_num" :max="info.stock" integer />
            </div>
          </div>
        </div>
        <div class="btnbox">
          <van-button round type="primary" color="#00C782" size="large" @click="order">确定</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
export default {
  props: {},
  data() {
    return {
      id: this.$route.query.id,
      slider: [],
      info: {},
      buy_show: false,
      pay_num: 1,
    }
  },
  created() {
    this.getdetails()
  },
  methods: {
    getdetails() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      })
      this.$post({
        url: "/app/goods3/info",
        data: {
          id: this.id,
        },
        success: (res) => {
          this.$toast.clear()
          this.slider = res.data.slider
          this.info = res.data
        },
        tip: () => { },
      })
    },

    order() {
      this.$router.push({
        name: "shopaorder",
        query: { id: this.id, num: this.pay_num },
      })
      this.buy_show = false
    },
  },
}
</script>

<style scoped lang='scss'>
.index {
  width: 100%;
  min-height: 100vh;
  height: auto;
  background: #ffffff;
  /deep/.van-nav-bar {
    color: #000;
    .van-nav-bar__title {
      color: #000;
    }
    .van-icon {
      color: #000;
    }
  }
  .bannbox {
    width: 100%;
    background: #fff;
    .my-swipe {
      /deep/.van-swipe-item {
        width: 100%;
        overflow: hidden;
        >img {
          width: 100%;
          height: 45vh;
          object-fit: contain;
        }
      }
    }
  }
  .minbox {
    width: 100%;
    background: #fff;
    padding: 2vw 3vw;
    .name {
      width: 100%;
      color: #0a0a0a;
      font-size: 32px;
      font-weight: 550;
    }
    .small_box {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      flex-wrap: wrap;
      .money {
        color: #007c51;
        font-size: 40px;
        font-weight: 550;
        span {
          font-size: 28px;
          font-weight: 500;
        }
      }
      .num {
        color: #000;
        font-size: 30px;
      }
    }
  }
  .block {
    width: 100%;
    height: 1vh;
    background: #f9f9f9;
  }
  .tip {
    width: 100%;
    padding: 2vw 0;
    display: flex;
    justify-content: center;
    align-items: center;
    >img {
      width: 40%;
      height: 6vw;
      object-fit: contain;
    }
  }
  .shangpin {
    width: 100%;
    height: auto;
    /deep/img,
    /deep/video,
    * {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .popup {
    .tit {
      padding: 2vw 0;
      width: 100%;
      text-align: center;
      font-size: 4.5vw;
      color: #000;
    }
    .listbox {
      width: 100%;
      padding: 2vw 3vw;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >img {
        width: 20vw;
        height: 20vw;
        object-fit: contain;
      }
      >div {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        padding-left: 15px;
        >p {
          text-align: left;
          font-size: 4vw;
          color: #e83323;
          font-weight: 550;
        }
        .stepper {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          >p {
            font-size: 3.6vw;
            color: #0a0a0a;
          }
          /deep/.van-stepper {
            display: flex;
          }
        }
      }
    }
    .btnbox {
      width: 100%;
      margin: 3vw 0;
      padding: 0 3vw;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
