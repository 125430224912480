<template>
  <div class='loginbox'>
    <van-nav-bar title="注册" :border='false' left-arrow @click-left="$router.go(-1)" fixed />
    <div class="frombox">
      <van-field clearable v-model="nickname" type="text" label="账号" placeholder="请输入账号" />
      <van-field clearable v-model="real_name" type="text" label="姓名" placeholder="请输入真实姓名" />
      <p class="inptit">真实姓名作为佣金提现的身份认证，不可乱填写！</p>
      <van-field clearable v-model="tell" type="tel" maxlength="11" label="手机号" placeholder="请输入您的手机号" />
      <van-field v-model="code" type="text" maxlength="11" label="验证码" clearable placeholder="请输入验证码">
        <template #button>
          <van-button round size="small" type="primary" color="#00C782" v-if="is_time == 1" @click="sendcode" class="anBtn">发送验证码</van-button>
          <van-button round size="small" type="primary" color="#00C782" v-if="is_time == 2" disabled class="anBtn">{{ time }}S后获取</van-button>
        </template>
      </van-field>
      <van-field clearable v-model="pass" type="password" label="登录密码" placeholder="请输入登录密码" />
      <van-field clearable v-model="passto" type="password" label="确认密码" placeholder="请输入确认登录密码" />
      <!-- <van-field clearable maxlength="6" v-model="paypass" type="password" label="支付密码" placeholder="请输入您的支付密码" />
      <van-field clearable maxlength="6" v-model="paypassto" type="password" label="确认密码" placeholder="请输入确认支付密码" /> -->
      <van-field clearable v-model="replacecdoe" type="text" :readonly='disabled' label="邀请码" placeholder="请输入邀请码" />
      <div class="btnbox">
        <van-button round type="primary" color="#00C782" block @click="registBtn">注册</van-button>
      </div>
      <div class="tit">
        <van-checkbox v-model="checked" icon-size="16">
          <span style="color:#8C9198">
            同意注册即接受
            <span @click="goOther(`agreement`) ">《用户注册协议》</span>
          </span>
        </van-checkbox>
      </div>
      <div class="tits" @click="$router.push('download')">
        <p>下载APP</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      logo: "",
      real_name: "",
      nickname: "",
      tell: "",
      code: "",
      pass: "",
      passto: "",
      paypass: "",
      paypassto: "",
      replacecdoe: "",
      disabled: false,
      time: 60,
      is_time: 1,
      downpath: "",
      download: "",
      checked: true,
    }
  },
  computed: {},
  watch: {},
  methods: {
    goOther(url) {
      this.$router.push(url)
    },
    sendcode() {
      if (!this.tell) return this.$toast("请输入手机号")
      if (!/^1[23456789]\d{9}$/.test(this.tell))
        return this.$toast("请输入正确格式的手机号")
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      })
      this.$post({
        url: "/app/auth/sendSms",
        data: {
          phone: this.tell,
          scene: "register",
        },
        success: () => {
          this.$toast.clear()
          this.$toast("发送成功")
          this.is_time = 2
          this.time = 60
          var timer = setInterval(() => {
            this.time--
            if (this.time == 0) {
              clearInterval(timer)
              this.is_time = 1
            }
          }, 1000)
        },
        tip: () => {},
      })
    },

    registBtn() {
      if (!this.nickname) return this.$toast("请输入账号")
      if (!this.real_name) return this.$toast("请输入真实姓名")
      if (!this.tell) return this.$toast("请输入手机号")
      if (!/^1[23456789]\d{9}$/.test(this.tell))
        return this.$toast("请输入正确格式的手机号")
      if (!this.code) return this.$toast("请输入验证码")

      if (!this.pass) return this.$toast("请输入登录密码")

      // if (!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/.test(this.pass))
      //   return this.$toast("登录密码必须是6至12位的字母数字组合")

      if (!this.passto) return this.$toast("请输入确认登录密码")
      if (this.pass !== this.passto)
        return this.$toast("两次输入的登录密码不一致")
      // if (!this.paypass) return this.$toast("请输入交易密码")
      // if (!/^\d{6}$/.test(this.paypass))
      //   return this.$toast("交易密码为6位数字格式")
      // if (!this.paypassto) return this.$toast("请输入确认交易密码")
      // if (this.paypass !== this.paypassto)
      //   return this.$toast("两次输入的交易密码不一致！")
      if (!this.replacecdoe) return this.$toast("请输入邀请码")
      if (!this.checked) return this.$toast("请勾选用户注册协议")

      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      })

      this.$post({
        url: "/app/auth/register",
        noToken: true,
        data: {
          nickname: this.nickname,
          real_name: this.real_name,
          phone: this.tell,
          sms_code: this.code,
          password: this.pass,
          re_password: this.passto,
          //   deal_password: this.paypass,
          //   sec_deal_password: this.paypassto,
          invite_code: this.replacecdoe,
        },
        success: () => {
          this.$toast.clear()
          this.$toast("注册成功")
          setTimeout(() => {
            if (!this.disabled) {
              this.$router.replace("login")
            } else {
              this.$router.push("download")
              // window.open(this.download)
            }
          }, 1500)
        },
        tip: () => {},
      })
    },
    getlogo() {
      this.$get({
        url: "/app/auth/download",
        success: (res) => {
          this.$toast.clear()
          this.download = res.data.download
        },
        tip: () => {},
      })
    },
    cldownload() {
      window.open(this.download)
    },
  },
  created() {
    this.getlogo()
    if (this.$route.query.code) {
      this.replacecdoe = this.$route.query.code
      this.disabled = true
    }
  },
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.loginbox {
  width: 100%;
  min-height: 100vh;
  height: auto;
  background: #fff;
  padding: 8vh 4vw 10vh;

  /deep/.van-nav-bar {
    .van-icon {
      color: #000;
    }
  }
  .frombox {
    width: 100%;
    /deep/.van-cell {
      margin-bottom: 1vw;
      padding: 3.5vw 0;
      border-radius: 8vw;
      background: rgba(255, 255, 255, 0.4);
      .van-field__label {
        text-align: center;
      }
    }
    .inptit {
      width: 100%;
      text-align: right;
      color: #00c782;
      font-size: 22px;
      line-height: 2.4;
    }
    /deep/.van-field {
      width: 100%;
      display: flex;
      align-items: center;
    }
    .btnbox {
      width: 100%;
      margin-top: 4vw;
    }
    .tit {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4vw 0;
      > a {
        color: #8c9198;
        font-size: 3.8vw;
      }
    }
    .tits {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2vw 0;
      > a {
        color: #8c9198;
        font-size: 3.8vw;
      }
    }
  }
}
</style>
