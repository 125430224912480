<template>
  <div class="mine">
    <van-nav-bar title="微信" left-arrow @click-left="onClickLeft" :border="false" />
    <div class="conbox">
      <div class="list">
        <!-- <van-field v-model="code" label="验证码:" center clearable placeholder="请输入短信验证码" v-if="isback">
          <template #button>
            <van-button size="small" color="#00C782" round type="default" v-if="is_time==1" @click="onSuccess">获取验证码</van-button>
            <van-button size="small" color="#00C782" round disabled v-else>{{time}}S后发送</van-button>
          </template>
        </van-field> -->
        <van-field v-model="wx_phone" maxlength="11" clearable label="手机号" placeholder="请输入手机号" />
        <van-field v-model="real_name" type="text" clearable label="姓名" placeholder="请输入真实姓名" />
        <van-field v-model="wechat" type="text" clearable label="微信号码" placeholder="请输入微信号码" />
        <!-- <div class="upimg">
          <p>微信收款码</p>
          <label for="unimg" class="imgbox">
            <img src="../../../assets/img/up.png" alt v-if="!wx_pic" />
            <img :src="httpPath+wx_pic" alt v-else />
            <input type="file" style="display:none;" id="unimg" ref="inputer" @change="uploadImage($event)" />
          </label>
        </div> -->
      </div>
    </div>
    <div class="btnbox">
      <van-button round color="#00C782" type="primary" size="large" @click="setAlipay">保存</van-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: "",
      wx_phone: "",
      real_name: "",
      wechat: "",
      wx_pic: "",
      isback: false, //未绑定
      time: 60, //获取验证码 时间
      is_time: 1,
      code: "",
    }
  },
  created() {
    this.getAmountData()
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
    onSuccess() {
      this.$post({
        url: "/app/member/sendSms",
        data: {
          scene: "pay",
        },
        success: () => {
          this.$toast({
            message: "发送成功",
            duration: 1000,
          })
          this.is_time = 2
          this.time = 60
          let showtime = setInterval(() => {
            this.time--
            if (this.time == 1) {
              clearInterval(showtime)
              this.is_time = 1
            }
          }, 1000)
        },
        tip: () => {},
      })
    },
    getAmountData() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      })
      this.$post({
        url: "/app/member_pay/getPay",
        success: (res) => {
          this.$toast.clear()
          if (res.data.is_bind_wx == 1) {
            this.isback = true
            this.wx_phone = res.data.wx_phone
            this.real_name = res.data.wx_name
            this.wechat = res.data.wx_account
            this.wx_pic = res.data.wx_code
          }
        },
        tip: () => {},
      })
    },

    uploadImage(event) {
      let DOM = this.$refs.inputer
      let formData = new FormData()
      formData.append("image", DOM.files[0]) //获取文件详细信息
      this.$toast.loading({
        message: "上传中...",
        forbidClick: true,
      })
      this.$post({
        url: "/app/member/uploadImg",
        data: formData,
        upload: true,
        success: (res) => {
          this.$toast.clear()
          if (res.code == 1) {
            this.wx_pic = res.data.path
          }
        },
        tip: (val) => {},
      })
    },

    setAlipay() {
      if (!this.wx_phone) return this.$toast("请输入手机号")
      if (!this.real_name) return this.$toast("请输入姓名")
      if (!this.wechat) return this.$toast("请输入微信号码")
      // if (!this.wx_pic) return this.$toast("请上传微信收款二维码")
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      })
      this.$post({
        url: "/app/member_pay/bindWx",
        data: {
          wx_phone: this.wx_phone,
          wx_name: this.real_name,
          wx_account: this.wechat,
          // wx_code: this.wx_pic,
        },
        success: (res) => {
          this.$toast.clear()
          this.$toast.success({
            message: res.msg,
            duration: 1000,
          })
          setTimeout(() => {
            this.$router.go(-1)
          }, 1000)
        },
        tip: () => {},
      })
    },
  },
}
</script>
<style scoped lang="scss">
.mine {
  width: 100%;
  min-height: 100vh;
  background: #ffffff;
  height: auto;
  padding-bottom: 4vh;
  /deep/.van-nav-bar {
    .van-icon {
      color: #000;
    }
    .van-nav-bar__title {
      color: #000;
    }
  }
  .conbox {
    width: 100%;
    .list {
      .upimg {
        width: 100%;
        border-radius: 3vw;
        padding: 3vw;
        background: #fcfcfc;
        > p {
          color: #323233;
          font-size: 4vw;
        }
        .imgbox {
          width: 100%;
          padding: 2vw 0;
          display: flex;
          justify-content: center;
          align-items: center;
          > img {
            max-width: 80%;
          }
        }
      }
      /deep/.van-cell {
        margin-bottom: 4vw;
        padding: 3vw;
        border-radius: 3vw;
        .van-field__label {
          color: #333333;
          font-size: 4vw;
        }
      }
    }
  }
  .btnbox {
    width: 100%;
    margin-top: 10vh;
    padding: 0 3vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
