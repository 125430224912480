<template>
  <div class='index'>
    <van-nav-bar title="提现" left-arrow :border="false" @click-left="$router.go(-1)" right-text="提现记录" @click-right="$router.push('withdrawallist')" />
    <div class="tobox">
      <p>提现至</p>
      <van-dropdown-menu>
        <van-dropdown-item v-model="pay_type" :options="option1" />
      </van-dropdown-menu>
    </div>

    <van-field v-model="name" label="姓名" placeholder="请输入姓名" clearable type="text" />
    <div class="infobox">
      <p>当前钱包余额: <span>{{info.balance}}</span> </p>
      <p>手续费:{{info.fee}}%</p>
    </div>
    <van-field v-model="amount" label="提现金额" placeholder="请输入提现金额" clearable type="number" />
    <van-field v-model="getamount" label="到账数量" readonly />
    <p class="block"></p>
    <div style="text-align:center;padding:10vw 0">
      <van-button type="primary" size="large" :loading='loading' color="#00C782" round @click="submit" style="margin:0 3vw;width:90vw">
        确认提现
      </van-button>
    </div>
    <div class="titbox">
      <p class="tit">提现须知</p>
      <p class="tit" v-html="info.info"></p>
      <p>最低提现{{info.min}}元,提现金额必须是{{info.multiple}}的倍数</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      option1: [
        { text: "银行卡", value: 3 },
        { text: "微信", value: 1 },
        { text: "支付宝", value: 2 },
      ],
      info: {},
      pay_type: 3, //1 微信  2支付宝 3 银行卡
      amount: "",
      name: "",

      loading: false,
    }
  },
  computed: {
    getamount() {
      let num = (
        Number(this.amount) -
        Number(this.amount * this.info.fee) / 100
      ).toFixed(2)
      return num
    },
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
    getdata() {
      this.$post({
        url: "/app/withdraw/getWithdrawInfo",
        success: (res) => {
          this.$toast.clear()
          this.info = res.data
        },
        tip: () => {},
      })
    },
    //静态
    submit() {
      if (!this.name) return this.$toast("请输入姓名")
      if (!this.amount) return this.$toast("请输入提现金额")

      this.loading = true
      this.$post({
        url: "/app/withdraw/withdrawDo",
        data: {
          amount: this.amount,
          name: this.name,
          pay_type: this.pay_type,
        },
        success: (res) => {
          this.$toast.clear()
          this.name = ""
          this.amount = ""
          this.$toast(res.msg)
          this.loading = false
          setTimeout(() => {
            this.$router.go(-1)
          }, 1500)
        },
        tip: () => {
          setTimeout(() => {
            this.loading = false
          }, 1200)
        },
      })
    },
  },
  created() {
    this.getdata()
  },
}
</script>

<style scoped lang='scss'>
.index {
  width: 100%;
  min-height: 100vh;
  background: #fff;
  /deep/.van-nav-bar {
    background: #fff;
    .van-icon,
    .van-nav-bar__text {
      color: #000;
    }
  }
  .tobox {
    width: 100%;
    padding: 2vw 3vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > p {
      font-size: 3.6vw;
      color: #000000;
      font-weight: 550;
    }
    /deep/.van-dropdown-menu {
      flex: 1;
      .van-dropdown-menu__bar {
        box-shadow: none;
      }
    }
  }
  .titbox {
    width: 100%;
    padding: 2vw 3vw 2vw;
    color: #f13b10;
    font-size: 3.6vw;
    .tit {
      color: #000000;
      font-weight: 550;
    }
  }
  .cell {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1vw 3vw;
    color: #666666;
    font-size: 3.4vw;
  }

  /deep/.van-cell__title {
    display: flex;
    align-items: center;
  }

  /deep/.van-cell {
    .van-button__text {
      color: #000;
      font-size: 4vw;
    }
  }

  .block {
    width: 100%;
    height: 1vh;
    background: #f9f9f9;
  }

  .infobox {
    width: 100%;
    background: #f5f5f5;
    padding: 2vw 3vw;
    flex-wrap: 550;
    p {
      width: 50%;
      color: #333333;
      font-size: 4vw;
      span {
        color: #007c51;
      }
    }
  }
}
</style>
