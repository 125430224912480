<template>
  <div class="indexbox">
    <van-nav-bar title="忘记密码" left-arrow @click-left="$router.go(-1)" fixed :border='false' />
    <div class="frombox">
      <van-field label="账 号" v-model="nickname" clearable placeholder="请输入账号" />
      <van-field label="手 机 号" v-model="tell" type="tel" maxlength="11" clearable placeholder="请输入手机号" />
      <van-field v-model="code" type="text" maxlength="11" clearable label="验 证码" placeholder="请输入验证码">
        <template #button>
          <van-button size="small" type="primary" round color="#007C51" v-if="is_time == 1" @click="sendcode" class="anBtn">发送验证码</van-button>
          <van-button size="small" type="primary" round color="#007C51" v-if="is_time == 2" disabled class="anBtn">{{ time }}S后获取</van-button>
        </template>
      </van-field>
      <van-field v-model="pass" clearable maxlength="12" type="password" placeholder="请输入登录密码(数字加字母6-12)" label="新 密 码" />
      <van-field v-model="passto" clearable maxlength="12" type="password" placeholder="请确认登录密码" label="确认新密码" />
      <div class="btnbox">
        <van-button type="default" block @click="registBtn" round color="#007C51" style="margin: 8vw 0" class="anBtn">确定</van-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      nickname: "",
      tell: "",
      code: "",
      pass: "",
      passto: "",
      disabled: false,
      time: 60,
      is_time: 1,
    }
  },
  methods: {
    sendcode() {
      if (!this.tell) return this.$toast("请输入手机号")
      if (!/^1[23456789]\d{9}$/.test(this.tell))
        return this.$toast("请输入正确格式的手机号")
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      })
      this.$post({
        url: "/app/auth/sendSms",
        data: {
          phone: this.tell,
          scene: "forget_password",
        },
        success: () => {
          this.$toast.clear()
          this.$toast("发送成功")
          this.is_time = 2
          this.time = 60
          var timer = setInterval(() => {
            this.time--
            if (this.time == 0) {
              clearInterval(timer)
              this.is_time = 1
            }
          }, 1000)
        },
        tip: () => {},
      })
    },
    registBtn() {
      if (!this.nickname) return this.$toast("请输入账号")
      if (!this.tell) return this.$toast("请输入手机号")
      if (!/^1[23456789]\d{9}$/.test(this.tell))
        return this.$toast("请输入正确格式的手机号")
      if (!this.code) return this.$toast("请输入验证码")
      if (!this.pass) return this.$toast("请输入登录密码")
      if (!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/.test(this.pass))
        return this.$toast("登录密码必须是6至12位的字母数字组合")
      if (!this.passto) return this.$toast("请输入确认登录密码")
      if (this.pass !== this.passto)
        return this.$toast("两次输入的登录密码不一致")
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      })

      this.$post({
        url: "/app/auth/forgetPassword",
        noToken: true,
        data: {
          nickname: this.nickname,
          phone: this.tell,
          sms_code: this.code,
          password: this.pass,
          re_password: this.passto,
        },
        success: () => {
          this.$toast.clear()
          this.$toast("修改成功")
          setTimeout(() => {
            this.$router.replace("login")
          }, 1500)
        },
        tip: () => {},
      })
    },
  },
  created() {},
}
</script>
<style lang='scss' scoped>
.indexbox {
  width: 100%;
  min-height: 100vh;
  height: auto;
  background: #ffffff;
  //   background: url("../../../assets/img/beijing@2x.png") no-repeat;
  //   background-size: 100% 100%;
  padding: 2vw 4vw;
  padding: 15vw 0;
  /deep/.van-nav-bar {
    background: none;
    .van-icon {
      color: #000;
    }
    .van-nav-bar__title {
      color: #000;
    }
  }
  .frombox {
    width: 100%;
    padding: 2vw 0;
    /deep/.van-cell {
      background: none;
    }
    .btnbox {
      width: 100%;
      padding: 0 4vw;
    }
  }
}
</style>
