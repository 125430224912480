<template>
  <div class='index'>
    <van-nav-bar title="提现记录" @click-left="$router.go(-1)" left-arrow />
    <van-tabs v-model="active" @click="onClick" sticky color='#00C782'>
      <van-tab v-for="val in tab" :title="val.tit" :key="val.ind">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :offset="0">
          <div class="list" v-for="val in list" :key="val.id">
            <p>提现金额：{{val.amount}}</p>
            <p>手续费：{{val.fee}}</p>
            <p>到账金额：{{val.actual}}</p>
            <p>姓名：{{val.name}}</p>
            <p>提现方式：{{val.pay_type==1?'微信':val.pay_type==2?'支付宝':val.pay_type==3?'银行卡':''}}</p>
            <p>提现状态：{{val.status_desc}}</p>
            <p>提现时间：{{val.create_time}}</p>
            <p class="block"></p>
          </div>
        </van-list>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      tab: [
        { tit: "全部", ind: 3 },
        { tit: "审核中", ind: 0 },
        { tit: "已完成", ind: 1 },
        { tit: "已驳回", ind: 2 },
      ],
      active: 0,
      list: [],
      page: 1,
      loading: false,
      finished: false,
    }
  },
  created() {},
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
    onClick() {
      this.page = 1
      this.list = []
      this.finished = false
    },
    onLoad() {
      var page = this.page++
      this.$post({
        url: "/app/withdraw/withdrawList",
        data: {
          page: page,
          status: this.tab[this.active].ind,
        },
        success: (res) => {
          console.log(res)
          if (!res.data || res.data.data.length == 0) {
            this.finished = true
          } else {
            var list = res.data.data
            if (page == 1) {
              this.list = list
            } else {
              for (var i in list) {
                this.list.push(list[i])
              }
            }
          }
          this.loading = false
        },
        tip: () => {
          this.finished = true
        },
      })
    },
  },
}
</script>

<style scoped lang='scss'>
.index {
  min-height: 100vh;
  background: #fff;
  padding: 0 0 2vh;
  /deep/.van-nav-bar {
    .van-icon {
      color: #000;
    }
  }

  .block {
    width: 100%;
    height: 1vh;
    background: #f9f9f9;
    margin-top: 1vw;
  }
  .list {
    width: 100%;
    padding: 0 1vw;
    p {
      width: 100%;
      padding: 1vw 2vw 0;
      font-size: 4vw;
      color: #000000;
    }
  }
}
</style>
