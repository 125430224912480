<template>
  <div class='index'>
    <van-nav-bar title="订单详情" :border="false" @click-left="$router.go(-1)" left-arrow />
    <div class="top">
      <div class="state" v-if="info.status == 1">
        <div class="left">
          <p class="title_one">待付款</p>
          <p class="title_two">订单等待付款</p>
        </div>
        <img src="../../../assets/img/ff@2x.png" class="state_img" />
      </div>
      <div class="state" v-if="info.status == 2">
        <div class="left">
          <p class="title_one">待发货</p>
          <p class="title_two">订单待发货，请耐心等待</p>
        </div>
        <img src="../../../assets/img/dd@2x.png" class="state_img" />
      </div>

      <div class="state" v-if="info.status == 3">
        <div class="left">
          <p class="title_one">待收货</p>
          <p class="title_two">订单已发货，请耐心等待</p>
        </div>
        <img src="../../../assets/img/dd@2x.png" class="state_img" />
      </div>

      <div class="state" v-if="info.status == 4">
        <div class="left">
          <p class="title_one">已完成</p>
          <p class="title_two">订单已完成</p>
        </div>
        <img src="../../../assets/img/cc@2x.png" class="state_img" />
      </div>

      <div class="state" v-if="info.status == 5">
        <div class="left">
          <p class="title_one">已取消</p>
        </div>
        <img src="../../../assets/img/cc@2x.png" class="state_img" />
      </div>
    </div>

    <div class="contactway" v-if="info.receive_type == 2">
      <img src="../../../assets/img/i@2x.png" class="address_img" />
      <div class="right">
        <p class="name">{{ info.consignee_name }} {{ info.consignee_mobile }}</p>
        <p class="address">{{ info.address }}-{{ info.detail }}</p>
      </div>
    </div>

    <div class="center">
      <img :src="httpPath + info.image" class="shangpin" />
      <div class="right">
        <p class="name">{{ info.goods_name }}</p>
        <div class="down">
          <p class="price">
            <span>￥</span>{{ info.goods_price }}
          </p>
          <p class="num">X{{ info.num }}</p>
        </div>
      </div>
    </div>

    <div class="allprice">
      <template>
        ￥{{ Number(info.goods_price) * info.num }}
      </template>
    </div>

    <div class="bottom">
      <p class="title">订单详情</p>
      <div class="ordernumber">
        <p>订单号：{{ info.order_no }}</p>
        <p class="copy" v-clipboard:copy="info.order_no" v-clipboard:success="onCopy" v-clipboard:error="onError">复制</p>
      </div>
      <p v-if="info.create_time">下单时间：{{ info.create_time }}</p>
      <p v-if="info.pay_time">下单时间：{{ info.pay_time }}</p>
      <p v-if="info.status !== 5 && info.receive_type > 0">收货方式：{{ info.receive_type == 1 ? '自提' : info.receive_type == 2
        ? '邮寄' : '' }}</p>
      <p v-if="info.status > 1 && info.status !== 5">付款方式：{{ info.pay_type == 1 ? '微信支付' : info.pay_type == 2 ||
        info.pay_type == 3 ?
        '支付宝支付' : info.pay_type == 4 ? '余额支付' : '' }}</p>
      <p v-if="info.delivery_time && info.status >= 3 && info.status !== 5">发货时间：{{ info.delivery_time }}</p>
      <p v-if="info.status >= 3 && info.status !== 5">物流名称：{{ info.express_name }}</p>
      <div class="ordernumber" v-if="info.status >= 3 && info.status !== 5">
        <p>发货单号：{{ info.express_no }}</p>
        <p class="copy" v-clipboard:copy="info.express_no" v-clipboard:success="onCopy" v-clipboard:error="onError">复制</p>
      </div>
    </div>

    <div class="btn">
      <van-button v-if="info.status == 1" type="info" color="#00C782" round @click="paydata"
        style="margin:0 0 0 2vw">立即付款</van-button>
      <van-button v-if="info.status > 2 && info.express_no" type="info" color="#00C782" round @click="lookwuliu"
        style="margin:0 0 0 2vw">查看物流</van-button>
      <van-button v-if="info.status == 3" type="info" color="#00C782" round @click="getconfirm"
        style="margin:0 0 0 2vw">确认收货</van-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      order_id: this.$route.query.order_id,
      info: {},
      paylist: {},

      showPopover: false,
      index: 1,
      imgshow: "",
      getPay: {},
      actions: [{ text: "微信" }, { text: "支付宝" }],
    }
  },
  created() {
    this.getdata()
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
    onCopy: function () {
      this.$toast({
        message: "复制成功",
        duration: 400,
      })
    },
    onError: function () {
      this.$toast({
        message: "复制失败",
        duration: 400,
      })
    },

    onSelect(e) {
      if (e.text == "微信") {
        this.index = 1
      } else {
        this.index = 2
      }
    },

    getconfirm() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确定收货吗?",
        })
        .then(() => {
          this.$post({
            url: "/app/orders/confirm",
            data: {
              order_id: this.order_id,
            },
            success: (res) => {
              this.$toast.success("收货成功")
              setTimeout(() => {
                this.getdata()
              }, 1000)
            },
            tip: () => { },
          })
        })
        .catch(() => {
          // on cancel
        })
    },

    paydata() {
      this.$router.push({
        name: "payorder",
        query: { order_id: this.info.id, order: 1 },
      })
    },

    lookwuliu() {
      let url = `https://m.kuaidi100.com/result.jsp?nu=${this.info.express_no}`
      this.$router.push({ name: "wuliu", query: { url } })
    },

    getdata() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      })
      this.$post({
        url: "/app/orders/info",
        data: {
          order_id: this.order_id,
        },
        success: (res) => {
          this.$toast.clear()
          this.info = res.data
          this.paylist = res.data.pay
          // if (res.data.pay.wx_code && res.data.pay.ali_code) {
          //   this.actions = [{ text: "微信" }, { text: "支付宝" }]
          //   this.index = 1
          // } else if (res.data.pay.wx_code) {
          //   this.actions = [{ text: "微信" }]
          //   this.index = 1
          // } else if (res.data.pay.ali_code) {
          //   this.actions = [{ text: "支付宝" }]
          //   this.index = 2
          // }
        },
        tip: () => { },
      })
    },
  },
}
</script>

<style scoped lang='scss'>
.index {
  width: 100%;
  min-height: 100vh;
  padding: 0 0 2vw;
  background: #f9f9f9;
  /deep/.van-nav-bar {
    background: #00c782;
    .van-icon {
      color: #fff;
    }
    .van-nav-bar__title {
      color: #fff !important;
    }
    .van-nav-bar__right {
      color: #fff;
    }
  }
  .top {
    width: 100%;
    background: #00c782;
    .state {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 5vh 3vw;
      .title_one {
        font-size: 4vw;
        font-weight: bold;
        color: #fff;
      }
      .title_two {
        font-size: 3.8vw;
        color: #fff;
        margin-top: 1vw;
      }
      .state_img {
        width: 10vw;
        height: 9vw;
        object-fit: contain;
      }
    }
  }
  .contactway {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 3vw;
    background: #fff;
    .address_img {
      width: 9vw;
      height: 9vw;
      margin-right: 2vw;
    }
    .name {
      font-size: 3.8vw;
      color: #000;
    }
    .address {
      font-size: 3.6vw;
      color: #000;
    }
  }
  .center {
    padding: 3vw;
    display: flex;
    flex-direction: row;
    border-top: 5px solid #f9f9f9;
    border-bottom: 1px solid #f9f9f9;
    background: #fff;
    .shangpin {
      width: 20vw;
      height: 20vw;
      border-radius: 2vw;
      margin-right: 3vw;
    }
    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      .name {
        font-size: 3.8vw;
        color: #000;
      }
      .down {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        .price {
          color: #00c782;
          font-size: 4vw;
          font-weight: 550;
          span {
            font-size: 3vw;
          }
        }
        .num {
          font-size: 3.2vw;
          color: #999;
        }
      }
    }
  }
  .allprice {
    font-size: 3.6vw;
    color: #00c782;
    padding: 3vw;
    text-align: right;
    border-bottom: 5px solid #f9f9f9;
    background: #fff;
    font-weight: 600;
  }
  /deep/.van-popover__wrapper {
    width: 100%;
  }
  .imgbox {
    width: 100%;
    padding: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    >img {
      width: 100%;
      height: 25vh;
      object-fit: contain;
    }
  }
  .code_box {
    width: 100%;
    background: #fff;
    .centers {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 3vw 2vw;
      >img {
        width: 100%;
        height: 25vh;
        object-fit: contain;
      }
      .showimg {
        width: 30%;
      }
    }
  }
  .bottom {
    padding: 3vw;
    background: #fff;
    font-size: 3.4vw;
    color: #000;
    .title {
      font-size: 4vw;
      color: #000;
      padding: 2vw 0;
    }
    .copy {
      font-size: 3.4vw;
      color: #00c782;
    }
    .ordernumber {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  .btn {
    width: 100%;
    background: #fff;
    border-top: 5px solid #f9f9f9;
    padding: 2vw 3vw;
    text-align: right;
    /deep/.van-button {
      height: 5vh;
      margin-left: 1vw;
    }
  }
}
</style>
