<template>
  <div class="index">
    <van-nav-bar title="资讯" fixed />
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :offset="0">
      <div class="listbox">
        <router-link :to="{ name: 'new', query: { id: val.id } }" class="list" v-for="(val, valIndex) in list"
          :key="valIndex">
          <div class="left">
            <p class="tit">{{ val.title }}</p>
            <p class="tit two">{{ val.small_text }}</p>
            <p v-html="val.create_time" class="content"></p>
          </div>
          <img v-lazy="httpPath + val.image" alt>
        </router-link>
      </div>
    </van-list>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      page: 1,
      loading: false,
      finished: false,
    }
  },
  created() { },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
    onLoad() {
      var page = this.page++
      this.$get({
        url: "/app/article/articleList",
        data: {
          page: page,
        },
        success: (res) => {
          if (!res.data || res.data.data.length == 0) {
            this.finished = true
          } else {
            var list = res.data.data
            if (page == 1) {
              this.list = list
            } else {
              for (var i in list) {
                this.list.push(list[i])
              }
            }
          }
          this.loading = false
        },
        tip: () => {
          this.finished = true
        },
      })
    },
  },
}
</script>

<style scoped lang='scss'>
.index {
  width: 100vw;
  min-height: 100vh;
  height: auto;
  box-sizing: border-box;
  padding: 12vw 0 130px;
  background: #fff;
  /deep/.van-nav-bar {
    background: #ffffff;
    .van-icon {
      color: #000;
    }
    .van-nav-bar__title {
      color: #000;
      font-weight: 550;
    }
  }
  .listbox {
    width: 100%;
    .list {
      width: 100%;
      margin-bottom: 2vw;
      padding: 2vw 3vw;
      border-bottom: 1px solid #f5f5f5;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        flex: 1;
        min-height: 150px;
        padding-right: 2vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        .tit {
          width: 100%;
          color: #000;
          font-weight: 550;
          font-size: 4vw;
        }
        .content {
          font-size: 3.4vw;
          color: #999;
        }
      }
      >img {
        height: 160px;
        width: 160px;
        border-radius: 10px;
        object-fit: contain;
      }
    }
  }
}
</style>
