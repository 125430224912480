<template>
  <div class="index">
    <van-nav-bar title="我的订单" left-arrow @click-left="$router.go(-1)" :border="false" />
    <van-tabs @change="onClick" v-model="active" background="#fff" :broder="false" line-height="2" sticky color='#00C782' title-active-color="#00C782">
      <van-tab :title="item.tit" v-for="item in titlist" :key='item.ind'>
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :offset="0">
          <div class="listbox" v-for="(val,valIndex) in list" :key="valIndex" @click="$router.push({name:'orderDetails',query:{order_id:val.id}})">
            <div class="top">
              <p>{{val.order_no}}</p>
              <p class="state">{{val.status==1?'待付款':val.status==2?'待发货':val.status==3?'待收货':val.status==4?'已完成':val.status==5?'已取消':''}}</p>
            </div>
            <div class="center">
              <img v-lazy="httpPath+val.image" class="shangpin" />
              <div class="right">
                <p class="name two">{{val.goods_name}}</p>
                <div class="down">
                  <p class="price">
                    <template><span>￥</span>{{val.goods_price}}</template>
                  </p>
                  <p class="num">X{{val.num}}</p>
                </div>
              </div>
            </div>
            <div class="bottom">
              <van-button round type="info" color="#00C782" v-if="val.status==1">立即付款</van-button>
              <van-button round type="info" color="#00C782" v-if="val.status==3">确认收货</van-button>
              <van-button round type="info" color="#00C782" v-if="val.status<5">查看详情</van-button>
              <van-button round type="info" color="#ECECEC" class="tit" v-if="val.status==5||val.status==6">查看详情</van-button>
            </div>
          </div>
        </van-list>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      active: 0,

      titlist: [
        { tit: "全部", ind: 0 },
        { tit: "待付款", ind: 1 },
        { tit: "待发货", ind: 2 },
        { tit: "待收货", ind: 3 },
        { tit: "已完成 ", ind: 4 },
      ],
      list: [],
      page: 1,
      finished: false,
      loading: false,
    }
  },
  created() {
    if (sessionStorage.getItem("trora")) {
      this.active = Number(sessionStorage.getItem("trora"))
    }
  },
  methods: {
    onClick(index) {
      sessionStorage.setItem("trora", index)
      this.page = 1
      this.list = []
      this.onLoad()
    },
    onLoad() {
      var page = this.page++
      this.$post({
        url: "/app/orders/myOrders",
        data: { status: this.active, page },
        success: (res) => {
          if (!res.data) {
            this.finished = true
            return
          }
          if (res.data.data.length == 0) {
            this.finished = true
          } else {
            var ret = res.data.data
            if (page == 1) {
              this.list = ret
            } else {
              for (var x in ret) {
                this.list.push(ret[x])
              }
            }
          }
          this.loading = false
        },
        tip: () => {
          this.finished = true
        },
      })
    },
  },
}
</script>

<style scoped lang='scss'>
.index {
  width: 100%;
  min-height: 100vh;
  height: auto;
  background: #fff;
  padding: 0 0 2vw;
  /deep/.van-nav-bar .van-icon {
    color: #000;
  }
  /deep/.van-tabs {
    width: 100%;
  }
  /deep/.van-tabs__line {
    background-color: #00c782 !important;
  }
  .pobox {
    width: 100%;
    padding: 5vw 0 2vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > div {
      width: 100%;
      padding: 2vw 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .listbox {
    width: 100%;
    background: #fff;
    border-bottom: 1.5vh solid #f9f9f9;
    padding: 3vw;
    .top {
      padding: 2vw 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: #000;
      font-size: 3.4vw;
      border-bottom: 1px solid #f5f5f5;
      .state {
        font-size: 3.4vw;
        color: #00c782;
      }
    }
    .center {
      padding: 3vw 0;
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid #f5f5f5;

      .shangpin {
        width: 20vw;
        height: 20vw;
        border-radius: 2vw;
        margin-right: 3vw;
      }
      .right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        .name {
          font-size: 3.8vw;
          color: #000;
        }
        .down {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          .price {
            color: #00c782;
            font-size: 3.4vw;
            font-weight: 550;
            span {
              font-size: 3vw;
            }
          }
          .num {
            font-size: 3.6vw;
            color: #999;
          }
        }
      }
    }
    .bottom {
      padding-top: 3vw;
      text-align: right;
      /deep/.van-button {
        height: 5vh;
        margin-left: 1vw;
      }
      .tit {
        color: #000 !important;
      }
    }
  }
}
</style>
