<template>
  <div class='indexbox'>
    <van-nav-bar title="选择配送方式" :border="false" @click-left="$router.go(-1)" left-arrow />
    <div class="bodybox">
      <van-radio-group v-model="receive_type">
        <van-cell-group>
          <van-cell title="自提" clickable @click="receive_type = 1">
            <template #right-icon>
              <van-radio :name="1" />
            </template>
          </van-cell>
          <van-cell title="邮寄" clickable @click=" receive_type = 2">
            <template #right-icon>
              <van-radio :name="2" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>

      <div class="contactway" v-if="receive_type == 2">
        <img src="../../../assets/img/i@2x.png" class="address_img" />
        <div class="right" v-if="addres.name" @click="$router.push('myAddress')">
          <p class="name">{{ addres.name }} {{ addres.mobile }}</p>
          <p class="address">{{ addres.area_desc }}-{{ addres.detail }}</p>
        </div>
        <div class="right" v-else>
          暂无地址，<span class="add" @click="$router.push('myAddress')">请添加地址</span>
        </div>
        <img src="../../../assets/img/gengduo@2x(1).png" alt="" class="righticon">
      </div>

      <van-button type="primary" block color="#00C782" :loading='loading' @click="setReceiveType">确定</van-button>
    </div>

  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      order_id: this.$route.query.order_id,
      order: this.$route.query.order,
      receive_type: 1,
      loading: false,
      addres: {},
    }
  },
  computed: {},
  watch: {},
  methods: {
    getdata() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      })
      this.$post({
        url: "/app/member_address/addressList",
        success: (res) => {
          this.$toast.clear()
          if (res.data.data.length > 0) {
            this.addres = res.data.data[0]
          }
        },
        tip: () => { },
      })
    },
    setReceiveType() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      })
      this.loading = true
      this.$post({
        url: "/app/orders/setReceiveType",
        data: {
          order_id: this.order_id,
          address_id: this.addres.id,
          receive_type: this.receive_type,
        },
        success: (res) => {
          this.$toast.clear()
          this.$toast("成功")
          this.loading = false
          setTimeout(() => {
            if (this.order == 1) {
              this.$router.go(-2)
            } else {
              this.$router.replace("/mine")
            }
          }, 1500)
        },
        tip: () => {
          this.loading = false
        },
      })
    },
  },
  created() {
    this.getdata()
  },
  mounted() { },
  beforeCreate() { }, //生命周期 - 创建之前
  beforeMount() { }, //生命周期 - 挂载之前
  beforeUpdate() { }, //生命周期 - 更新之前
  updated() { }, //生命周期 - 更新之后
  beforeDestroy() { }, //生命周期 - 销毁之前
  destroyed() { }, //生命周期 - 销毁完成
  activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.indexbox {
  width: 100%;
  min-height: 100vh;
  background: #f4f4f4;
  /deep/.van-nav-bar {
    background: #fff;
  }
  .bodybox {
    width: 100%;
    padding: 2vw 4vw;
    .contactway {
      width: 100%;
      margin-top: 2vh;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 3vw;
      background: #fff;
      .address_img {
        width: 10vw;
        height: 10vw;
      }
      .right {
        flex: 1;
        padding: 0 2vw;
        .name {
          font-size: 4vw;
          color: #000;
        }
        .address {
          font-size: 3.8vw;
          color: #000;
        }
        .add {
          font-size: 3.8vw;
          color: #00c782;
        }
      }
      .righticon {
        width: 3vw;
        height: 6vw;
      }
    }
    /deep/.van-button {
      margin-top: 4vh;
      border-radius: 2vw;
    }
  }
}
</style>