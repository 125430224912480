import axios from 'axios';
import router from './../router'
import qs from 'qs';
import Vant from 'vant';
import { Lazyload } from 'vant';
import JSONBIG from 'json-bigint';
export default {
  install: Vue => {
    Vue.use(Vant);
    Vue.use(Lazyload, {
      lazyComponent: true,
    });
    Vue.prototype.$axios = axios
    // axios.defaults.withCredentials = true
    // js返回进度丢失
    axios.defaults.transformResponse = [
      function (data) {
        const json = JSONBIG({
          storeAsString: true
        })
        const res = json.parse(data)
        return res
      }
    ]
    axios.defaults.timeout = 300000; //请求超时
    const no_login_code = -1; //未登录返回的状态码
    const success_code = 1; //成功返回的状态码
    const err_code = 0; //失败返回的状态码

    if (process.env.NODE_ENV == 'development') setPath('http://www.sdxinghua.art') //设置开发环境域名
    else if (process.env.NODE_ENV == 'production') setPath('') //设置正式生产环境域名
    // else if (process.env.NODE_ENV == 'debug') setPath('') //设置测试环境域名

    function setPath(path) {
      axios.defaults.baseURL = path;
      Vue.prototype.httpPath = path;
      Vue.prototype.wsPath = '';//WebSocket
    }
    Vue.prototype.$get = params => {
      const token = localStorage.getItem("token") || "";
      axios
        .get(params.url, {
          params: Object.assign({}, params.data, token ? {
            token
          } : "")
        })
        .then(res => {
          if (res.data.code == no_login_code) {
            Vue.prototype.$toast('未登录');
            if (token) localStorage.removeItem("token");
            setTimeout(() => {
              router.replace('login')
            }, 1500);
          } else if (res.data.code == success_code) {
            console.group('%c' + params.url, 'color:#6600FF;line-height:30px;padding:10px 0;', res.data)

            if (params.success)
              params.success(res.data);
          } else if (res.data.code == err_code) {
            if (params.tip) {
              params.tip('val')
              Vue.prototype.$toast(res.data.msg);
              console.group('%c' + params.url, 'color:#6600FF;line-height:30px;padding:10px 0;', res.data)
            } else if (params.tips) {
              // 无轻提示
              params.tips('vals')
              console.group('%c' + params.url, 'color:#6600FF;line-height:30px;padding:10px 0;', res.data)
            }
          }
        })
        .catch(err => {
          console.log('服务器请求失败', err.response)
          if (err.response.status == '404') {
            Vue.prototype.$toast({
              messgae: '接口不存在'
            });
            // Vue.prototype.$toast('接口不存在');
          } else if (err.response.status == '500') {
            Vue.prototype.$toast('服务器内部错误');
          } else if (params.fail)
            params.fail(err);
        })
    }

    Vue.prototype.$post = params => {
      const token = localStorage.getItem("token") || "";
      let $axios;
      if (params.upload) {
        params.data.append('token', token ? token : "")
        let config = {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        };
        $axios = axios.post(params.url, params.data, config)
      } else if (params.noToken) {
        let data = qs.stringify(Object.assign({}, params.data))
        $axios = axios.post(params.url, data)
      } else {
        let data = qs.stringify(Object.assign({}, params.data, token ? {
          token
        } : {}))
        $axios = axios.post(params.url, data)
      }

      $axios
        .then(res => {
          if (res.data.code == no_login_code) {
            Vue.prototype.$toast('未登录');
            if (token) localStorage.removeItem("token");
            setTimeout(() => {
              router.replace('login')
            }, 1500);
          } else if (res.data.code == success_code) {
            console.group('%c' + params.url, 'color:#6600FF;line-height:30px;padding:10px 0;', res.data)
            if (params.success)
              params.success(res.data);
          } else if (res.data.code == err_code) {
            if (params.tip) {
              params.tip('val')
              Vue.prototype.$toast(res.data.msg);
              console.group('%c' + params.url, 'color:#6600FF;line-height:30px;padding:10px 0;', res.data)
            } else if (params.tips) {
              params.tips('vals')
              console.group('%c' + params.url, 'color:#6600FF;line-height:30px;padding:10px 0;', res.data)
            }
            // 无轻提示
          }
        })
        .catch(err => {
          console.log('服务器请求失败', err)
          if (err.response.status == '404') {
            Vue.prototype.$toast('接口不存在');
          } else if (err.response.status == '500') {
            Vue.prototype.$toast('服务器内部错误');
          } else if (params.fail)
            params.fail(err);
          // if (params.fail)
          //     params.fail(err);
        })
    }

  }
}