import Vue from 'vue'
import App from './App'
import router from './router/index'
import http from './request/http'
import 'vant/lib/index.css';
import './assets/css/reset.css';

Vue.use(http)
Vue.config.productionTip = false;
//复制粘贴
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
//常规验证码 运算验证码 滑动验证码 拼图验证码 选字验证码
import Verify from 'vue2-verify'
Vue.component('Verify', Verify)
//vue扩展
import vue_fun from './function/vue_fun.js';
Vue.use(vue_fun);
// 中英文版本插件
// import VueI18n from 'vue-i18n'
// Vue.use(VueI18n)
// 实例
// const i18n = new VueI18n({
// 	locale: (function () {
// 		if (localStorage.getItem('lang')) {
// 			return localStorage.getItem('lang')
// 		}
// 		return 'zh'
// 	}()),
// 	// eslint-disable-next-line no-dupe-keys
// 	locale: 'zh',
// 	messages: {
// 		'zh': require('./assets/i18n/zh'),   // 中文语言包
// 		'en': require('./assets/i18n/en')    // 英文语言包 
// 	}
// })


new Vue({
    // i18n,
    router,
    render: h => h(App),
}).$mount('#app')


//        _==/          i     i          \==_
//      /XX/            |\___/|            \XX\
//    /XXXX\            |XXXXX|            /XXXX\
//   |XXXXXX\_         _XXXXXXX_         _/XXXXXX|
//  XXXXXXXXXXXxxxxxxxXXXXXXXXXXXxxxxxxxXXXXXXXXXXX
// |XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX|
// XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
// |XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX|
//  XXXXXX/^^^^"\XXXXXXXXXXXXXXXXXXXXX/^^^^^\XXXXXX
//   |XXX|       \XXX/^^\XXXXX/^^\XXX/       |XXX|
//     \XX\       \X/    \XXX/    \X/       /XX/
//        "\       "      \X/      "      /"
