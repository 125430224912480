<template>
  <div class='index'>
    <van-nav-bar :title="tit" fixed @click-left="$router.go(-1)" left-arrow>
      <template #right>
        <van-button v-if="type == 1||type == 2" type="primary" size="mini" color="#F13B10" @click="$router.push({name:'withdrawal',query:{type}})">提现</van-button>

        <van-button v-if="type == 3||type == 4" type="primary" size="mini" color="#F13B10" @click="$router.push({name:'turn',query:{type}})">转赠</van-button>
      </template>
    </van-nav-bar>
    <div class="headerbox">
      <p>{{this.type == 1?info.static:this.type == 2?info.dynamic:this.type == 3?info.coin:this.type == 4?info.code:info.point}}</p>
      <p>{{tit}}</p>
    </div>
    <div class="titbox">
      <img src="../../../assets/img/a@2x.png" alt="">
      明细
    </div>
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :offset="0">
      <div class="list" v-for="val in list" :key="val.id">
        <div class="left">
          <p class="address">{{val.remark}}</p>
          <p class="time">{{val.create_time}}</p>
        </div>
        <p class="number_red" v-if="val.sign==1">+{{val.amount}}</p>
        <p class="number_green" v-else>{{val.amount}}</p>
      </div>
    </van-list>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      info: {},
      tit: "",
      type: this.$route.query.type,
      list: [],
      page: 1,
      loading: false,
      finished: false,
    }
  },
  created() {
    if (this.type == 1) {
      this.tit = "静态钱包"
    } else if (this.type == 2) {
      this.tit = "动态钱包"
    } else if (this.type == 3) {
      this.tit = "工单费钱包"
    } else if (this.type == 4) {
      this.tit = "VIP码钱包"
    } else if (this.type == 5) {
      this.tit = "公益钱包"
    }
    this.member()
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
    member() {
      this.$post({
        url: "/app/member/memberInfo",
        success: (res) => {
          this.info = res.data
        },
        tip: (val) => {},
      })
    },
    onLoad() {
      var page = this.page++
      this.$post({
        url: "/app/balance_log/balanceList",
        data: {
          page: page,
          balance_type: this.type,
        },
        success: (res) => {
          console.log(res)
          if (!res.data || res.data.data.length == 0) {
            this.finished = true
          } else {
            var list = res.data.data
            if (page == 1) {
              this.list = list
            } else {
              for (var i in list) {
                this.list.push(list[i])
              }
            }
          }
          this.loading = false
        },
        tip: () => {
          this.finished = true
        },
      })
    },
  },
}
</script>

<style scoped lang='scss'>
.index {
  min-height: 100vh;
  background: #fff;
  padding: 7.5vh 0 2vh;
  /deep/.van-nav-bar {
    .van-icon {
      color: #000;
    }
  }
  /deep/.van-nav-bar__right {
    color: #000;
  }
  /deep/.van-nav-bar__title {
    color: #000 !important;
  }
  .headerbox {
    width: 100%;
    padding: 5vh 2vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #f13b10;
    color: #fff;
    font-size: 4vw;
    P:nth-child(1) {
      font-size: 5vw;
      font-weight: 600;
      line-height: 2;
    }
  }
  .titbox {
    width: 100%;
    padding: 2vw;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #333333;
    font-size: 3vw;
    > img {
      width: 6vw;
      height: 6vw;
      margin-right: 2vw;
      object-fit: contain;
    }
  }
  .list {
    width: 100%;
    padding: 2vw;
    border-radius: 2vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f5f5f5;
    .left {
      .address {
        font-size: 3.8vw;
        color: #000;
        margin-bottom: 1vh;
      }
      .time {
        font-size: 3.4vw;
        color: #999999;
      }
    }
    .number_red {
      font-size: 4vw;
      color: #f13710;
    }
    .number_green {
      font-size: 4vw;
      color: #4a6ef5;
    }
  }
}
</style>
